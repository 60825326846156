@import '/src/assets/stylesheets/variables.scss';

.conversation-list-row {
  .conversation {
    display: flex;
    flex-direction: row;
    padding: 16px 8px;
    justify-content: space-between;
  }

  .conversation-metadata {
    word-break: unset;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    .resolved-conversation {
      background-color: $colorAlertPositive;
      .icon:before {
        font-size: 20px;
        color: $colorWhite;
        top: 0;
      }
    }
    .unresolved-conversation {
      background-color: $colorDisabledGrey;
      .icon:before {
        font-size: 20px;
        color: $colorInactiveDarkGrey;
      }
      .icon-hourglass {
        margin-top: 3px;
      }
    }
  }

  .conversation-body {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .conversation-title {
      font-size: 16px;
      font-weight: 500;
    }
    .conversation-message-body {
      white-space: pre-wrap;
      font-size: 14px;
      font-weight: 400;
    }
    .conversation-message-footer {
      font-size: 14px;
      font-weight: 400;
      color: $colorInactiveDarkGrey;
    }
  }

  .app-link {
    align-self: center;
    font-weight: 500;
    .icon:before {
      font-size: 14px;
      top: 2px;
    }
  }

  .message-separator-show {
    border-top: 1px solid $colorInactiveGrey;
  }

  .message-separator-hide {
    border-top: none;
  }
  .conversation-card-date-hide {
    display: none;
  }
  .conversation-card-date {
    padding: 5px;
    background: $colorSecondary;
    border-radius: 6px;
    color: $colorPrimaryDark;
  }
}
