@import 'assets/stylesheets/variables.scss';

.profile-drop-down {
  &:active {
    background-color: $colorDisabledGrey;
  }

  .MuiIconButton-root {
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }

  .profile-icon {
    display: flex;
    column-gap: 12px;
    align-items: center;
    #profile-icon-image {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      color: $colorPrimary;
    }
    #profile-icon-username {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $colorPrimary;
    }
  }
}

.profile-dropdown-menu {
  display: flex;
  flex-direction: column;
  width: '100%';
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-focusVisible.MuiMenuItem-root.MuiMenuItem-gutters {
    @media screen and (max-width: 1024px) {
      background-color: transparent;
    }
  }
  .user-info-container {
    display: flex;
    width: 100%;
    padding: 0 15px;
    column-gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid $colorDisabledGrey;

    &:hover {
      background-color: inherit;
    }
    .user-image {
      width: 100px;
      height: 100px;
      border-radius: 100px;
    }
    .user-information {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
      .user-name {
        font-size: 24px;
        font-weight: 400;
      }
      .user-email {
        font-size: 14px;
        font-weight: 400;
      }
      .user-type {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .change-password-and-logout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .change-password {
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      justify-content: center;
      padding: 8px 15px;
      row-gap: 4px;
      border-bottom: 1px solid $colorDisabledGrey;
      flex-direction: column;
      align-items: baseline;
      &.expiry-warning {
        height: 50px;
      }
      &:hover {
        background-color: $colorPrimaryLight;
      }
      &:focus {
        background-color: $colorPrimary;
        color: $colorWhite;
      }
    }
    .logout {
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      justify-content: flex-start;
      padding: 0 15px;
      height: 38px;

      &:hover {
        background-color: $colorPrimaryLight;
      }
    }
  }
}

.password-expiry-string {
  margin: 0;
  line-height: 14px;
  color: $colorAlertNegative;
}

.password-expiry-red-dot {
  width: 10px;
  height: 10px;
  background-color: #e76767;
  border-radius: 50%;
  position: absolute;
  left: 26px;
  top: 0px;
}
