@import 'assets/stylesheets/variables.scss';

.diary-validity-text {
  font-size: 12px;
  font-weight: 400;
  color: $colorInactiveGrey;
}
.diary-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $colorInactiveGrey;
}

.diary-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.diary-card {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  padding: 16px 0px 16px 8px;
  border-bottom: 1px solid #bcccd3;
  &:last-child {
    border: 0;
  }

  .diary-body {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 144px;

    .diary-unread-icon {
      color: $colorAlertNegative;
    }

    .diary-read-icon {
      color: $colorDisabledGrey;
    }
  }

  .diary-activity {
    width: calc(100% - 145px);
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .diary-card-buttons {
    display: flex;
    flex-direction: column;
    justify-content: start;
    button {
      height: 16px;
    }
    .icon-edit:before {
      color: $colorPrimary;
    }
  }
}
