@import "../../../assets/stylesheets/variables.scss";

.mobile-loader {
    display: flex;
    justify-content: center;

    .mini-spinner-image {
        height: 40px;
        width: 77px;
        border-radius: 20px;
    }
}

.full-screen-loader-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(black, 0.28);

    .full-spinner-image {
        width: 70px;
        height: 48px;
    }

    .full-screen-loader {
        background-color: $colorWhite;
        width: 77px;
        height: 77px;
        justify-content: center;
        align-items: center;
        display: flex;
        margin: auto;
        border-radius: 20px;
    }
}
