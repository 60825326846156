@import 'assets/stylesheets/variables.scss';

.dashboard-navigation-bar {
  padding: 20px 40px;
  display: flex;
  height: 60px;
  column-gap: 40px;
  align-items: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  .contact-us-item {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    .help-text-label {
      align-self: center;
      color: $colorInactiveDarkGrey;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
