@import '../../assets/stylesheets/variables';

// .lightbox-modal {
//   background: rgba($colorBlack, 0.2);

.modal-content {
  background: transparent;
  box-shadow: none;
  border: none;
  text-align: center;
}

.spinner-image {
  width: 70px;
  height: 70px;
  border-radius: 16px;
  opacity: 0.8;
}
// }
