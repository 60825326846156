@import 'assets/stylesheets/variables.scss';

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .generated-form-buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
    flex-wrap: wrap;
    row-gap: 8px;
  }
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select File';
  display: inline-block;
  background: $colorSecondary;
  border: 1px solid $colorInactiveDarkGrey;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  width: 100px;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.form_generator_helper_text {
  color: $colorPrimary;
  font-size: 14px;
}
