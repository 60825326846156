@import 'src/assets/stylesheets/variables.scss';

.view-data-download-request-container {
  display: grid;
  grid-template-rows: 60px calc(100% - 90px - 60px) 90px;

  width: 100%;

  .welcome-header {
    margin: 15px;
    margin: 0 15px;
    font-weight: 300;
    font-size: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $colorPrimaryDark;
  }

  .login-form-container {
    margin-top: 160px;

    padding: 0 35%;
    @media screen and (max-width: 1180px) {
      padding: 0 15%;
    }
    width: 100%;
    .resend-code-message-and-text {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
    .enter-pin-form {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
  }

  .login-form-view {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .timer {
      color: #7c7c7c;
    }

    .resend-code {
      color: #2b80bb;
    }
  }

  .input-container {
    margin-top: 15px;
    text-align: center;
  }

  input {
    width: 50%;
    margin: auto;
    text-align: center;
    font-size: 20px !important;
  }
  .input-label-without-value,
  .input-label-with-value {
    position: relative;
  }
  .input-label-without-value {
    top: 20px;
  }
  .input-label-with-value {
    top: 5px;
  }
  .text-info {
    font-size: 18px;
    font-weight: 300;
    color: #000;
    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .text-info-small {
    font-size: 12px;
    font-weight: 300;
    color: #000;
    margin: 5px 5px 5px 5px;
    @media screen and (max-width: 575px) {
      font-size: 12px;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 12px;
    }

    .link {
      color: $colorPrimary;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }
    .not-active {
      color: darkgray !important;
      pointer-events: none;
      cursor: default;
      text-decoration: none;
    }
  }

  .btn-primary {
    font-size: 16px;
    width: 130px;
    height: 30px;
    margin: 30px 10px 10px 10px;
  }
  .download {
    width: 200px !important;
  }

  .help-block {
    color: $colorAlertNegativeDark;
  }
  .request-data-status {
    text-align: center;
    margin-top: 160px;
    padding: 0 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
    @media screen and (max-width: 1180px) {
      padding: 0 15%;
    }
    .data-container {
      padding-bottom: 5px;
      font-weight: 300;
      font-size: 14px;
      .lbl-name {
        display: inline-block;
      }
      .value {
        display: inline-block;
      }

      .expiring-text {
        font-size: 12px;
      }
    }
  }
}
