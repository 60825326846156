@import 'assets/stylesheets/variables.scss';

.note-create-buttons {
  display: flex;
  column-gap: 8px;
}

.notes-tab-header-left {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  .vertical-line {
    border: 1px solid $colorInactiveGrey;
    margin: 3px 0px 3px 0px;
  }
  .notes-header-left-text {
    color: $colorPrimaryDark;
  }
  .notes-count {
    line-height: 32px;
  }

  .notes-tab-header-right {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
  }
  .value-container-placeholder-icon {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    padding-right: 5px;
    color: $colorInactiveGrey;
  }
}
.communication-log-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.notes-header-date {
  padding: 5px;
  background: $colorSecondary;
  border-radius: 6px;
  color: $colorPrimaryDark;
}

.line-sep {
  border-bottom: 1px solid $colorDisabledGrey;
  height: 10px;
}

.add-log-button-container {
  padding: 0px 20px;
  color: $colorPrimary;

  .add-log-button {
    padding-right: 0px;
    .add-log-button-icon {
      &:before {
        font-size: 20px;
        top: 2px;
      }
    }
    .add-log-button-text {
      line-height: 2;
      padding: 0px 5px;
      margin-top: 4px;
    }
  }
}

.create-note-form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .note-title {
    width: 650px;
  }
}

.create-log-modal {
  .title-container {
    padding: 15px 0px;
  }

  textarea {
    margin-top: 5px;
  }

  .log-description {
    padding: 20px 0px;
  }
  .log-shared-container {
    padding: 10px 15px 5px 15px;

    .shared-label {
      padding-right: 10px;
      line-height: 30px;
    }

    .radio-button-container {
      height: 30px;
      // padding-left: 20px;
      // margin-left: 10px
    }

    .shared-text-content {
      margin-bottom: 5px;
      font-weight: 300;

      float: left;
      margin-left: 10px;
      line-height: 30px;
    }
  }
}

.attachment-section {
  padding: 5px 0px;
}
