@import '/src/assets/stylesheets/variables.scss';

.task-count-header {
  padding-left: 5px;
  padding-right: 10px;
  text-align: center;
  float: left; // border-right: 1px solid $colorDisabledGrey;
}

.tasks-container {
  display: grid;
  grid-template-rows: 48px calc(100% - 48px);
  padding: 0px 0px 0px 8px;

  .tasks-header {
    padding: 10px 0px;

    .task-count-header {
      color: $colorPrimaryDark;
    }
  }

  .tasks-list {
    display: flex;
    flex-direction: column;
  }
}
