@import '/src/assets/stylesheets/variables.scss';

@import '~rc-time-picker/assets/index.css';
.clear-icon {
  &.icon:before {
    font-size: 16px;
  }
}

.timer-icon {
  &.icon:before {
    font-size: 16px;
  }
}

.close-icon {
  font-size: 10px;
  border-radius: 0px;
  right: 3px;
  width: 33%;
  position: absolute;
  bottom: 0;
  background: $colorPrimary;
  line-height: 14px;
  color: $colorSecondary;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.rc-time-picker {
  display: block !important;
  box-shadow: none !important;
  border: 2px solid $colorDisabledGrey !important;

  .rc-time-picker-input {
    font-size: 14px;
    width: 100%;
    border: none !important;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1024px) {
      padding-left: 2px;
    }
    height: 32px !important;
    border-radius: 0;
    padding: 8px;
    background: $colorWhite;
  }
  .rc-time-picker-input[disabled] {
    background: $colorWhite;
  }
}

.rc-time-picker-panel {
  width: 100%;
  .rc-time-picker-panel-inner {
    border: none;
    box-shadow: none;
    width: 100%;
    .rc-time-picker-panel-input-wrap {
      box-shadow: none !important;
      border: 2px solid $colorDisabledGrey !important;
      box-sizing: border-box;
      padding: 5px;
      top: 3px;
      border-radius: 4px;
    }
    .rc-time-picker-panel-combobox {
      width: 100.5%;
      .rc-time-picker-panel-select {
        width: 33.3%;
        overflow-x: hidden;
        border: 1px solid #e9e9e9;
        min-height: 144px;
        max-height: 144px;
        li {
          padding: 0 35%;
        }
      }
      .rc-time-picker-panel-select li:hover {
        background: $colorSecondary;
      }
      li.rc-time-picker-panel-select-option-selected {
        background: $colorSecondary;
        color: $colorPrimary;
      }
      li.rc-time-picker-panel-select-option-disabled {
        color: #bfbfbf;
      }
      li.rc-time-picker-panel-select-option-disabled:hover {
        background: transparent;
        cursor: not-allowed;
      }
    }
  }
}
