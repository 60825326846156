@import 'assets/stylesheets/variables.scss';
.notification-dropdown {
  &:active {
    background-color: $colorDisabledGrey;
  }
  .MuiIconButton-root {
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }
  .MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
    padding-top: 0 !important;
  }
  .dropdown-ul {
    padding-top: 0 !important;
  }
  & svg {
    font-size: 30px !important;
  }

  #notification-icon {
    // width: 16px;
    // height: 20px;
    color: $colorBlack;
    transform: rotate(25deg);
  }

  .pending-notifications-badge {
    position: absolute;
    top: 3px;
    left: 18px;
    background: $colorAlertNegative;
    line-height: 20px;
    border-radius: 10px;
    color: $colorWhite;
    font-size: 12px;
    font-weight: 300;
    display: block;
    min-width: 20px;
    text-align: center;
    padding: 0 2px;
    animation: pulse 0.5s 1;
    @media screen and (max-width: 572px) {
      left: 26px;
      top: 15px;
    }
  }
}

.notification-dropdown-menu {
  width: 100%;
  padding: 0px !important;
  cursor: default !important;
  &:hover {
    background-color: transparent !important;
  }
}
.notifications-container {
  // min-width: 380px;
  // height: 400px;
  // margin-top: -5px;
  width: 100%;
  .notfications-dropdown-header {
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    cursor: default !important;
    &:hover {
      background-color: inherit;
    }
    .notifications-dropdown-title {
      font-size: 20px;
      font-weight: 500;
    }
    .see-all-link {
      font-size: 16px;
      color: $colorPrimary;
      font-weight: 700;
      cursor: pointer;
      &.disabled {
        color: $colorDisabledGrey;
        cursor: default;
      }
    }
  }
  .notifications-dropdown-body {
    height: calc(400px - 46px);
    overflow: auto;
    &:hover {
      background-color: inherit;
    }
    .notifications-empty-container {
      text-align: center;
      font-size: 20px;
      font-weight: 100;
      margin-top: 20px;
      .empty-container {
        .text {
          font-size: 12px;
          font-weight: 300;
          color: $colorDisabledGrey;
        }
      }
      img {
        width: 75px;
      }
    }
    .nav-notification-card {
      padding: 10px 15px;
      font-weight: 100;
      font-size: 14px;
      .timestamp {
        margin-top: 10px;
        color: $colorInactiveDarkGrey;
        font-weight: 300;
      }
      .content {
        color: $colorBlack;
        font-weight: 300;
        white-space: normal;
      }
      &.unread {
        .content,
        .timestamp {
          color: $colorBlack;
          font-weight: 500;
        }
      }
    }
    .Wrapper-dyuznp {
      margin: 1em auto;
      width: 24px;
      height: 24px;
    }
  }
  .loader-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-focusVisible.MuiMenuItem-root.MuiMenuItem-gutters {
  background-color: transparent;
}
