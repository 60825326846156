@import 'src/assets/stylesheets/variables.scss';

.consent-tab-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .consent-count {
    color: $colorPrimaryDark;
    padding: 8px;
  }
}

.border-line {
  border-bottom: 1px solid $colorDisabledGrey;
  margin: 10px 0;

  &:last-child {
    border-bottom: none;
  }
}

.consent-bucket-title {
  background-color: $colorSecondary;
  padding: 7px;
  color: $colorPrimaryDark;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
}

.consent-card-wrapper {
  padding: 8px 0px 8px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .consent-card-body {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .consent-card-title {
      font-size: 16px;
      color: $colorBlack;
      font-weight: 400;
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      align-items: center;

      .icon-download {
        color: $colorPrimary;
      }
    }

    .consent-card-status {
      font-size: 12px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      .completed {
        display: flex;
        column-gap: 8px;
        align-items: center;
        color: $colorAlertPositiveDark;
      }
      .consent-card-cs-status {
        .pending-on-me-subject-complete {
          display: flex;
          column-gap: 8px;
          align-items: center;
          color: $colorAlertNegative;
        }
        .pending-on-me {
          display: flex;
          column-gap: 8px;
          align-items: center;
          color: $colorAlertNeutralDark;
        }

        .pending-on-other {
          display: flex;
          column-gap: 8px;
          align-items: center;
          color: $colorAlertNeutralDark;
        }
      }

      .consent-card-subject-status {
        .subject-consent-pending {
          color: $colorAlertNeutralDark;
          display: flex;
          column-gap: 8px;
          align-items: center;
        }

        .subject-consent-completed {
          color: $colorAlertPositiveDark;
          display: flex;
          column-gap: 8px;
          align-items: center;
        }
      }
    }
  }

  .upload-consent-button-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: flex-end;

    .upload-consent-button-wrapper-text {
      font-weight: 400;
      font-size: 14px;
    }

    .upload-consent-button {
      width: fit-content;
    }
  }
}

.upload-consent-form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .rdtPicker {
    top: 0;
  }
  .upload-consent-form-fields {
    border: 1px solid $colorInactiveGrey !important;
  }
  .MuiFormControl-root
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl
    fieldset.MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }
  #select-counter-signe {
    .single-select__value-container,
    .single-select__value-container--has-value {
      height: 34px !important;
    }
  }
  .password-container {
    z-index: 0;
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl {
      height: 36px;
    }
    .MuiOutlinedInput-input.MuiInputBase-input {
      padding: 6.5px 14px;
    }
    .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined.MuiFormLabel-root {
      font-size: 14px !important;
    }
  }
  .has-error {
    .date-picker-field {
      border: 1px solid $colorAlertNegative !important;
    }
  }
  .date-picker-field {
    padding: 8px 0px;
    .form-control {
      padding: 8px;
      height: 34px !important;
      margin-top: -8px !important;
    }
  }

  .counter-signe-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 24px;
    .counter-signe-name {
      min-width: 55%;
    }
    .date-picker-field {
      min-width: 272px;
      width: 100%;
      .form-control {
        height: 34px !important;
        &:disabled {
          margin: -8px 0px 0px 0px !important;
          width: inherit;
        }
      }
    }
  }
}
