@import 'assets/stylesheets/variables.scss';
.communication-log-details-container {
  padding: 0px 8px 0 32px;

  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .note-details-page-icons {
    font-size: 24px;
  }

  .response-container {
    display: flex;
    flex-direction: column;
    padding: 24px 4px;
    width: 50%;
    .response-label {
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      color: $colorPrimary;
      .icon-addchatbubbleoutline {
        font-size: 24px;
      }
      .response-label-title {
        color: $colorInactiveDarkGrey;
      }
    }

    .comment-area {
      margin-top: 8px;
      border: 1px solid $colorDisabledGrey;
      border-radius: 4px;
      resize: none;
    }
  }

  .edit-comment-modal {
    .edit-comment-attachment-section {
      margin-top: 10px;
    }
  }
}
