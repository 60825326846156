@import 'assets/stylesheets/variables.scss';

.notes-date-bucket {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .notes-header-date {
    padding: 5px;
    background: $colorSecondary;
    border-radius: 6px;
    color: $colorPrimaryDark;
  }
}
