@import 'assets/stylesheets/variables.scss';

// .navbar {
//   margin-bottom: 0px !important; // @media screen and (max-width: 572px) {
//   //   margin-bottom: 0px !important;
//   // }
//   // @media screen and (min-width: 572px) and (max-width: 736px) {
//   //   margin-bottom: 5px !important;
//   // }
//   @media screen and (max-width: 768px) {
//     min-height: 60px !important;
//   }
// }

.navigation-bar {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  .navigation-bar-logo {
    img {
      width: 140px;
      height: 27px;
    }
  }

  .navigation-bar-lookup {
    width: 55%;
    @media screen and (max-width: 768px) {
      width: 45%;
    }
    @media screen and (max-width: 1024px) {
      width: 50%;
    }
  }
  .navigation-bar-profile-notifications {
    display: flex;
    column-gap: 36px;
    @media screen and (max-width: 1024px) {
      column-gap: 12px;
    }
  }
  .dropdown {
    // float: right;
    .profile-icon {
      display: flex;
    }
    #profile-icon-username {
      line-height: 42px;
      margin-left: 8px;
      color: $colorPrimary;
    }
  }
  .custom-dropdown {
    padding: 0;
    .icon:before {
      top: 10px;
    }
  }
  li.nav-dropdown-scrollable > ul.dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }
  .navbar-nav > li > a {
    padding: 10px;
  }
  .navbar-header {
    margin: 15px 0px 10px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .mms-logo {
        height: 30px;
      }
    }
  }

  .navbar-brand {
    padding: 10px 20px 10px 20px;
    img {
      width: 170px;
      height: 32px;
      margin: 0px;
    }
    .nav-logo {
      display: flex;
    }
    .brand-name {
      color: $colorPrimary;
    }
  }

  // @media screen and (max-width: 768px) {
  //   .navbar-brand {
  //     padding: 5px;
  //     img {
  //       margin-left: 8px;
  //     }
  //   }
  //   .navbar-header {
  //     width: 50px;
  //     float: left;
  //   }
  //   .navbar-nav {
  //     margin: -58px -15px !important;
  //   }
  // }
}

.header-container .nav {
  li {
    &:focus {
      outline: none;
    }
    a {
      line-height: 32px !important;
      &:focus {
        outline: none;
      }
    }
  }
}

.dropdown-toggle span.caret {
  color: #8a6e93;
}

.container-fluid {
  height: 60px;
  #flex-item-1 {
    margin: 0;
  }
  #flex-item-2 {
    flex: auto;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lookup-form .lookup input {
  width: 100%;
}
