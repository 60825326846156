@import '../../assets/stylesheets/variables.scss';

.attachment-row {
  width: auto;

  .attachment-name {
    margin: 0 6px;
    color: $colorPrimary;
    line-height: 30px;
    font-size: 12px;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    @media screen and (max-width: 572px) {
      max-width: 245px;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      float: left;
    }

    @media screen and (max-width: 320px) {
      max-width: 200px;
    }
  }

  .remove-attachment-icon {
    padding-top: 2px;
  }

  .attachment-icon {
    display: inline-block;
    float: left;
    .icon::before {
      top: 4px;
      font-size: 12px;
    }
  }

  .remove-attachment-container {
    border: 1px solid $colorPrimary;
    border-radius: 15px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    color: $colorPrimary;
    height: 32px;
    margin-bottom: 8px;
    margin-right: 8px;
    .attachment-name {
      margin-top: 4px;
    }

    > span {
      // margin-left: 10px;
    }
    .remove-icon {
      float: right;
      // margin-right: 10px;
      // margin-top: 2px;

      &:before {
        font-size: 12px;
        top: 0px;
      }
    }
  }
}

// .separation-0 {
//   border: 0;
//   padding-left: 0;
// }
