@mixin set_border($borderColor, $borderWidth: 2px) {
  border-color: $borderColor;
  $border-width: $borderWidth;
}

@mixin set_border_and_label_for_active_state(
  $labelColor,
  $borderColor: $colorPrimary,
  $borderWidth: 2px
) {
  // MUI INPUT BOX
  .MuiOutlinedInput-root.MuiInputBase-root.Mui-focused.MuiInputBase-formControl {
    fieldset.MuiOutlinedInput-notchedOutline {
      @include set_border($borderColor, $borderWidth);
    }
  }
  // MUI INPUT BOX LABEL
  label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.Mui-focused {
    color: $labelColor;
  }
}

@mixin set_border_and_label_for_inactive_state(
  $labelColor,
  $borderColor: $colorPrimary,
  $borderWidth: 2px
) {
  label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root {
    color: $labelColor;
  }

  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl {
    input.MuiOutlinedInput-input.MuiInputBase-input {
      color: $colorBlack;
      caret-color: $colorPrimary;
    }
    // MUI class for floating label
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $borderColor;
      border-width: $borderWidth;
    }
  }
}

@mixin set_border_and_label_for_disabled_state(
  $labelColor,
  $borderColor: $colorPrimary,
  $borderWidth: 2px
) {
  .MuiOutlinedInput-root.MuiInputBase-root.Mui-disabled.MuiInputBase-formControl {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $borderColor;
      border-width: $borderWidth;
    }
  }
  label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.Mui-disabled {
    color: $labelColor;
  }
}

@mixin set_border_and_label_for_filled_unFocused_state(
  $labelColor,
  $borderColor: $colorPrimary,
  $borderWidth: 2px
) {
  // MUI TextFiled Label CSS class
  label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-filled {
    color: $labelColor;
  }
  label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-filled
    + div
    fieldset.MuiOutlinedInput-notchedOutline {
    border-color: blue;
    border-width: 2px;
  }
}

@mixin set_border_and_label_for_error_state(
  $labelColor,
  $borderColor: $colorPrimary,
  $borderWidth: 2px
) {
  .MuiOutlinedInput-root.MuiInputBase-root.Mui-error.MuiInputBase-formControl {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $colorAlertNegative;
      border-width: $borderWidth;
    }
  }
  label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.Mui-error {
    color: $colorAlertNegative;
  }
}
