/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: https://www.linotype.com
*/

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-ot/MuseoSans_100.otf') format('otf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-ot/MuseoSans_300.otf') format('otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-ot/MuseoSans_500.otf') format('otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-ot/MuseoSans_700.otf') format('otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-ot/MuseoSans_900.otf') format('otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-ot/MuseoSans_100_Italic.otf') format('otf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-ot/MuseoSans_300_Italic.otf') format('otf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-ot/MuseoSans_500_Italic.otf') format('otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-ot/MuseoSans_700_Italic.otf') format('otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-ot/MuseoSans_900_Italic.otf') format('otf');
  font-weight: 900;
  font-style: italic;
}

/* then for WOFF-capable browsers */
@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-web/MuseoSans_100Web.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-web/MuseoSans_300Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-web/MuseoSans_500Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-web/MuseoSans_700Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-web/MuseoSans_900Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-web/MuseoSans_100_ItalicWeb.woff')
      format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-web/MuseoSans_300_ItalicWeb.woff')
      format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-web/MuseoSans_500_ItalicWeb.woff')
      format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-web/MuseoSans_700_ItalicWeb.woff')
      format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'),
    url('../fonts/museo-sans-family-web/MuseoSans_900_ItalicWeb.woff')
      format('woff');
  font-weight: 900;
  font-style: italic;
}

.font-museo {
  font-family: 'MuseoSans';
}

/* font for  Holister  HelveticaNeue */

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato-fonts/Lato-Light.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato-fonts/Lato-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato-fonts/Lato-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato-fonts/Lato-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato-fonts/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
