@import 'assets/stylesheets/variables.scss';

.user-admin-wrapper {
  .nav-tabs {
    display: flex;
    justify-content: space-between;
    border: 2px solid $colorInactiveGrey;

    width: 100%;
    li {
      width: 100%;
      height: 56px;

      a {
        height: 56px;
        margin: 0 !important;
        border: 1px solid $colorInactiveGrey;
        border-radius: 0px;
        .tab-header {
          width: fit-content;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          column-gap: 8px;
        }

        &:active {
          outline: none;
          outline-offset: none;
        }
      }
    }
    .active {
      a {
        border-bottom: none;
        background-color: $colorPrimaryDark !important;
        color: $colorWhite !important;
      }
    }
  }
  .admin-tab-content-wrapper {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    height: calc(100vh - 60px - 56px);
    .admin-tab-filter-section {
      border-right: 2px solid $colorInactiveGrey;
      padding: 16px;
      .filters-container {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        .filter-wrapper {
          display: flex;
          flex-direction: column;
          row-gap: 16px;
        }
        .filter-buttons {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
        }
      }
    }
    .admin-tab-data-section {
      display: grid;
      grid-template-rows: 60px calc(100% - 120px) 60px;
      height: calc(100vh - 120px);
      padding: 0 16px;
      .admin-tab-data-section-header {
        margin-top: auto;
        margin-bottom: auto;
        padding: 18px;
      }
      .admin-tab-data-section-content {
        padding: 8px;
        overflow: auto;
      }
      .admin-tab-data-section-footer {
        padding: 8px;
      }
    }
  }
}

.add-edit-form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .inline-form {
    display: flex;
    column-gap: 8px;
    width: 100%;
    align-items: center;
    input[type='checkbox'] {
      width: 16px;
      height: 16px;
      accent-color: $colorPrimary;
      margin: 0;
    }
  }
}

.preview-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  row-gap: 16px;

  .add-user-form-preview {
    display: grid;
    grid-auto-flow: column;
    // grid-template-columns: repeat( auto-fill, minmax(220px, 1fr) );

    :last-child {
      border: none !important;
    }

    .details {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: 0 16px 0 8px;
      border-right: 0.5px solid $colorInactiveGrey;
      .title {
        font-size: 12px;
        font-weight: 500;
        color: $colorPrimaryDark;
        display: flex;
        .title-text {
          line-height: 42px;
        }
      }
      .columns {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        .info {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          min-width: 136px;
          .info-key {
            font-size: 12px;
            font-weight: 700;
            color: $colorInactiveDarkGrey;
          }
          .info-value {
            font-size: 16px;
            font-weight: 400;
            color: $colorBlack;
          }
        }
      }
    }
  }
  .buttons {
    display: flex;
    column-gap: 16px;
    margin-left: auto;
  }
}
