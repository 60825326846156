@import 'assets/stylesheets/variables.scss';

.event-container {
  border: 1px solid $colorDisabledGrey;
  background: $colorWhite;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;

  .visit-details {
    display: grid;
    grid-template-columns: 27% 27% 27% 17%;
    gap: 0px 0px;
    grid-auto-flow: column;
    padding: 10px 12px 10px 24px;
    justify-content: space-between;
    border-right: 0.5px solid $colorInactiveGrey;
  }

  .icon-edit:before {
    color: $colorPrimary;
    font-size: 15px;
  }
  .icon-delete:before {
    color: $colorAlertNegative;
    font-size: 15px;
  }
  .icon-add-circle:before,
  .add-button {
    font-size: 23px;
  }

  .visit-notes {
    display: grid;
    grid-template-columns: 0.8fr 0.2fr;
    gap: 0px 0px;
    grid-auto-flow: column;
    padding: 10px 12px;
    justify-content: space-between;
    border-left: 0.5px solid $colorInactiveGrey;
  }

  .edit-visit-note {
    justify-self: end;
  }

  .visit-column {
    display: grid;
    grid-template-rows: 0.2fr 1fr;
  }

  .visit-title {
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: $colorInactiveDarkGrey;
  }

  .modify-visit {
    display: flex;
    padding-top: 1px;
    justify-content: flex-end;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 16px;
    }
    @media screen and (max-width: 1336px) and (orientation: landscape) {
      display: flex;
      flex-direction: column-reverse;
      gap: 16px;
    }
  }

  .visit-data {
    margin-top: 10px;
    overflow: hidden;
    white-space: pre-wrap;
  }

  .icon-schedule:before,
  .icon-font-a-calendar:before {
    position: relative;
    color: $colorPrimaryDark;
    font-size: 16px;
    top: 2px;
  }
}
