@import 'assets/stylesheets/variables.scss';

.diary-bucket {
  .diary-header {
    padding: 5px;
    background: $colorSecondary;
    border-radius: 6px;
    color: $colorPrimaryDark;
  }
}
