@import '/src/assets/stylesheets/variables.scss';

.pagination-container {
  display: flex;
  justify-content: flex-end;

  .total-results-container {
    .results-count {
      line-height: 30px;
    }
  }
  .pagination-body-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .go-to-page {
      display: flex;
      margin: 0 5px 0 10px;
      .page-input {
        width: 48px;
        input {
          height: 24px;
          text-align: center;
          border: 1px solid $colorInactiveGrey;
          border-radius: 4px 0px 0px 4px;
          @media screen and (max-width: 1024px) {
            height: 28px;
          }
        }
      }
      .btn-primary {
        border: none !important;
        margin-left: -1px;
      }
      .goto-btn-pagination {
        border-radius: 0 4px 4px 0;
        height: 24px;
        width: 88px;
        @media screen and (max-width: 1024px) {
          width: 92px;
          height: 28px;
        }
      }
    }
    .prev-page {
      margin-left: 5px;
      color: $colorPrimary !important;
      height: 24px;

      &.disabled {
        border-color: $colorDisabledGrey !important;
        color: $colorDisabledGrey !important;
      }
    }
    .next-page {
      margin-left: 5px;
      color: $colorPrimary !important;
      height: 24px;
      &.disabled {
        border-color: $colorDisabledGrey !important;
        color: $colorDisabledGrey !important;
      }
    }
    .page-nav-icon {
      border: 1px solid $colorInactiveGrey;
      display: inline-block;
      line-height: 30px;
      width: 28px;
      height: 24px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 13px;

      &:hover {
        border: 1px solid $colorPrimary;
        color: $colorPrimary;
      }

      .icon {
        position: relative;
        top: -6px;
        left: -7px;
        -webkit-text-stroke-width: 0.5px;
        &:before {
          font-size: 13px;
        }
      }
      &.disabled,
      &.disabled:hover {
        cursor: default;
        border: 1px solid $colorDisabledGrey;
        color: $colorDisabledGrey;
      }
    }
  }
  .form-group {
    display: inline-block;
    margin-bottom: 0;

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input {
      width: 35px;
      height: 32px;
      border: 1px solid lightgray;
      border-radius: 5px 0px 0px 5px;
    }
  }
  .btn-primary {
    height: 32px;
    line-height: 20px;
    padding: 5px;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid $colorPrimaryDark;
    border-radius: 0px 5px 5px 0px;
  }
}
