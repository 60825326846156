@import 'assets/stylesheets/variables.scss';

.note-details-back-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .icon-font-a-left-chevron:before {
    color: $colorPrimary;
    font-size: 18px;
    top: 0;
    font-weight: bold !important;
  }
  .icon-notes:before {
    color: $colorInactiveDarkGrey;
    align-self: center;
    line-height: 24px;
    font-size: 20px;
  }
  .note-details-back-button-text {
    color: $colorInactiveDarkGrey;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-left: 5px;
  }
}

.communication-log-info {
  padding: 16px 8px;
  border: 1px solid $colorDisabledGrey;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .communication-log-title {
    font-weight: 500;
    font-size: 16px;
  }

  .horizontal-line {
    border: 1px solid $colorDisabledGrey;
    margin: 0px 0px 8px 0px;
  }

  .btn-text {
    color: $colorPrimary;
  }

  .attachments-container {
    padding: 0;
  }

  .title-container {
    padding: 10px 0px 3px 0px;

    .show {
      padding-right: 0px;
      float: right;
    }
  }

  .communication-log-description {
    font-size: 14px;
    font-weight: 400;
  }
}

.edit-log-modal {
  .title-container {
    margin-bottom: 10px;
  }

  .log-shared-container {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 20px 0px;

    .shared-label {
      padding-right: 20px;
      line-height: 30px;
    }
    .radio-button-container {
      padding-left: 20px;
      margin-left: 10px;
    }
    .shared-text-content {
      margin-bottom: 5px;
      font-weight: 300;
    }
  }

  textarea {
    margin-top: 5px;
  }

  .log-description {
    margin-top: 10px;
  }
}
