@import 'assets/stylesheets/variables.scss';
.conversation-container {
  display: grid;
  grid-template-rows: 48px calc(100% - 48px);
  .conversations-tab-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .conversations-tab-header-left {
      display: flex;
      flex-direction: row;
      padding: 0px 8px;
      column-gap: 8px;
      .vertical-line {
        border: 1px solid $colorInactiveGrey;
        margin: 3px 0px 3px 0px;
      }
      .conversations-header-left-text {
        color: $colorPrimaryDark;
      }
      .conversations-count {
        line-height: 32px;
      }
    }
    .conversations-tab-header-right {
      display: flex;
      flex-direction: row;
      column-gap: 8px;
    }
    .value-container-placeholder-icon {
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      padding-right: 5px;
      color: $colorInactiveGrey;
    }
  }
  .conversations-list {
    display: flex;
    flex-direction: column;
  }
}

#start-conversation-form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.message-buttons {
  min-width: 200px;
  margin-top: 16px;
}
