@charset "UTF-8";

@font-face {
  font-family: 'untitled-font-5';
  src: url('../fonts/notes-fonts/untitled-font-5.eot');
  src: url('../fonts/notes-fonts/untitled-font-5.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/notes-fonts/untitled-font-5.woff') format('woff'),
    url('../fonts/notes-fonts/untitled-font-5.ttf') format('truetype'),
    url('../fonts/notes-fonts/untitled-font-5.svg#untitled-font-5')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'untitled-font-5' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-notes:before,
.icon-addchatbubbleoutline:before {
  font-family: 'untitled-font-5' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-notes:before {
  content: '\61';
}
.icon-addchatbubbleoutline:before {
  content: '\62';
}
