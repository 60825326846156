@import '/src/assets/stylesheets/variables.scss';

.empty-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  .icon:before {
    font-size: 100px;
    color: $colorInactiveGrey;
  }

  .img {
    text-align: center;
    img {
      width: 150px;
      height: 150px;
    }
  }

  .text {
    text-align: center;
    margin-top: 10px;
    color: $colorInactiveDarkGrey;
    white-space: pre;
  }
}
