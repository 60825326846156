@import 'assets/stylesheets/variables.scss';

.pre-login-footer-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  div {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
  }

  background: $colorSecondary;
  padding: 10px;
  font-size: 12px;
  font-weight: 300;

  @media screen and (min-width: 736px) and (max-width: 768px) {
    font-size: 9px;
  }

  .contact-us {
    float: left;
    font-weight: 700;
  }

  .about-us {
    color: $colorPrimary;
    margin-left: 10px;
    float: left;
  }

  .dot {
    margin-left: 10px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: $colorInactiveGrey;
    float: left;
  }

  .contact-number {
    color: $colorInactiveGrey;
    margin-left: 10px;
    float: left;
  }

  .copy-right-container {
    order: 0;
    flex: 1 0 auto;
    align-self: auto;
    text-align: right;
    color: $colorInactiveGrey;
  }
}
