@import '/src/assets/stylesheets/variables.scss';

.patient-details-tab-content-wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-auto-columns: 1fr;
  gap: 8px 0px;
  grid-auto-flow: row;
  height: 100%;
  grid-template-areas:
    ''
    ''
    '';

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    .header-left-section {
      color: $colorPrimaryDark;
    }

    .header-right-section {
      color: $colorPrimary;

      .icon:before {
        right: 8px;
        top: 2px;
        font-size: 20px;
      }
    }
  }
  .wrapper-body {
    overflow: auto;
  }
}

.survey-tab-wrapper {
  gap: 0px 0px;
}
.note-tab-wrapper {
  padding-bottom: 8px;
}

.patient-details {
  .patient-details-main-container {
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
    width: 100vw;
    padding: 24px 24px 24px 24px;
    row-gap: 24px;
    height: 100%;

    .tab-menu {
      padding-bottom: 24px;
      clear: both;
      height: 100;

      .tab-menu-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // margin-left: 10px;
        position: relative;
        // top: -3px;
        color: $colorInactiveDarkGrey;
      }

      .nav-justified {
        height: 40px;

        li {
          display: table-cell;
          width: 1%;
        }
      }

      ul {
        li,
        li:focus {
          text-align: center;
          padding: 0 5px;
          vertical-align: top;

          &:first-child {
            padding: 0 5px 0 0;
          }

          &:last-child {
            padding: 0 0 0 5px;
          }

          a {
            outline: 0;
            padding: 5px;
            border: 1px solid;
            border-radius: none;
            background: $colorPrimaryLight;
            // margin-bottom: 5px;
            // padding-bottom: 8px;
            border-bottom: none;
            border-color: $colorPrimary;
            height: 40px;

            .tab-header {
              margin-top: 6px;
              display: flex;
              flex-direction: row;
              column-gap: 8px;
              justify-content: center;
              align-items: center;

              .icon:before {
                top: 0;
              }
            }
          }

          a:hover {
            background: $colorWhite;
          }

          .icon:before {
            color: $colorInactiveDarkGrey;
            font-size: 16px;
          }

          .consent-tab-icon {
            color: $colorInactiveDarkGrey;
          }
        }

        li.active {
          a,
          a:hover,
          a:focus {
            padding: 5px 5px;
            margin-bottom: 0;
            background-color: $colorWhite;
            border-bottom: none;
            border-color: $colorPrimary !important;
            border-image-slice: 1;
            // border-top: 4px solid;
            // border-width: 5px;
            // border-top: 3px solid $colorPrimaryGradient;
            border-radius: 4px 4px 0 0;
            outline: 0;
          }

          .icon:before {
            top: 0px;
            color: $colorPrimary;
            font-size: 16px;
          }

          .consent-tab-icon {
            color: $colorPrimary;
          }

          .tab-menu-title {
            // top: -7px;
            color: $colorPrimary;
          }

          @media screen and (max-width: 768px) {
            // width: 25%;
            .tab-menu-title {
              display: inline;
            }
          }
        }
      }

      .count-container {
        position: absolute;
        right: -10px;
        top: -15px;
        background: $colorAlertNegative;
        border-radius: 50%;
        color: $colorWhite;
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        min-width: 20px;
        min-height: 20px;
      }

      .notify-container {
        right: 45px;
      }
    }

    .single-tab {
      ul {
        li:first-child,
        li:last-child {
          padding: 0px;
        }
      }
    }

    .tab-content {
      flex: auto;
      padding: 0 16px 0 16px;
      border: 1px solid $colorPrimary;
      // margin-top: -1px;
      border-radius: 0 0 8px 8px;
      min-height: 50vh;
      height: calc(100% - 40px);
      overflow: hidden;

      // border-top: none;
      @media print {
        overflow: visible !important;
        border: none;
      }

      .charts-frame {
        min-height: 550px;
        width: calc(100vw - 42px);
        height: calc(100vh - 90px);
        border: none;
      }

      @media screen and (max-width: 768px) {
        .charts-frame {
          height: calc(100vh - 380px);
        }
      }

      .outcome-reports-empty-state {
        padding: 40px;
        width: 100%;
        text-align: center;
        font-size: 16px;

        img {
          height: 150px;
        }
      }

      $tab-class-slug: patient-details-tab-pane !default;

      @for $i from 1 through 6 {
        ##{$tab-class-slug}-#{$i} {
          // padding: 16px;
          background: $colorWhite;
        }
      }

      .tab-pane {
        height: 100%;
      }
    }

    .line {
      border-bottom: 1px solid $colorDisabledGrey;
    }

    // .location-select {
    //   border: none;
    //   border-radius: 0px;
    //   box-shadow: none;
    //   border-bottom: 1px solid $colorDisabledGrey;
    //   margin-bottom: 10px;
    //   font-size: 14px;
    // }
  }

  .profile-tab-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: end;
    margin-top: 5px;
  }

  .active a .profile-tab-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-top: 0;

    @media screen and (min-width: 768px) and (max-width: 991px) {
      margin: 2px 0;
    }
  }

  #patient-details-tab-pane-8 {
    .tab-details-title {
      margin-bottom: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding: 20px 20px 0 20px;
    }
  }
}
