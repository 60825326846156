@import 'assets/stylesheets/variables.scss';

.user-id-container {
  display: inline-block;
  line-height: 21.6px;
  font-weight: 500;
  font-size: 18px;
  color: $colorBlack;
  @media screen and (max-width: 572px) {
    font-size: 12px;
  }
}

.user-mrn-container {
  border: 1px solid $colorPrimary;
  border-radius: 4px;
  display: inline-block;
  background: $colorSecondary;
  line-height: 25px;
  font-weight: 300;
  font-size: 14px;
  color: $colorPrimary;
  @media screen and (max-width: 572px) {
    font-size: 12px;
  }

  .mrn-label {
    float: left;
    text-align: center;
    border-right: 1px solid $colorPrimary;
    padding: 0 10px;
  }
  .mrn-value {
    float: left;
    text-align: center;
    padding: 0 12px;
  }
}
