@charset "UTF-8";

@font-face {
  font-family: 'mms';
  src: url('../fonts/mms-font/mms-font-multiselect.eot');
  src: url('../fonts/mms-font/mms-font-multiselect.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/mms-font/mms-font-multiselect.woff') format('woff'),
    url('../fonts/mms-font/mms-font-multiselect.ttf') format('truetype'),
    url('../fonts/mms-font/mms-font-multiselect.svg#mms') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'mms' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: inherit;
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-2a-']:before,
[class*=' icon-2a-']:before {
  font-family: 'mms' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: inherit;
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-2a-checked:before {
  content: '\61';
}
.icon-2a-unchecked:before {
  content: '\62';
}
