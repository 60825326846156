@import 'assets/stylesheets/variables.scss';

.add-patient-form-button {
  font-size: 14px;
  .cancel-button {
    width: 150px;
    @media screen and (max-width) {
      width: 180px;
    }
  }
}
.checkbox-container {
  position: relative;
  margin-top: 5px;
  .icon.icon-font-a-check-box:before,
  .icon.icon-font-a-check-mark:before {
    color: $colorPrimary;
    top: 6px;
  }
  .compliance-icon {
    position: absolute;
    top: -5px;
  }
  .check-box-label {
    margin-top: 5px;
    margin-left: 25px;
  }
}

.inline-form {
  width: 32%;
}

.add-patient-modal {
  .procedure-date-info,
  .note-for-supported-keys,
  .general-cardiologist-info {
    padding: 5px 10px;
    background-color: $colorDisabledGrey;
    font-size: 12px;
    margin: 15px 0;
    color: $colorInactiveGrey;
    border-radius: 4px;
  }
  .clear-data {
    padding: 5px 10px;
    font-size: 14px;
    margin: 15px 0;
    color: $colorDisabledGrey;
    border-radius: 4px;
    width: 15%;
    float: left;
    text-align: right;
    .clear-label {
      position: relative;
      top: -4px;
    }
    .icon:before {
      font-size: 18px;
      top: 0px;
      left: -3px;
    }
    .active-icon:before {
      color: $colorPrimary;
    }
    .deactiver-icon:before {
      color: $colorDisabledGrey;
    }
    .active-label {
      color: $colorPrimary;
    }
    .deactive-label {
      color: $colorDisabledGrey;
    }
  }
  .add-patient-summary-title {
    font-size: 14px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .summary-separation {
    height: 1px;
    border-bottom: 1px solid $colorDisabledGrey;
    margin: 5px 0;
  }
  .add-patient-summary-row {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
    .summary-label {
      width: 230px;
      display: block;
    }
    .summary-row-value {
      font-weight: 300;
      display: block;
    }
  }
  .margin-top-10 {
    margin-top: 10px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  .summary-wrapper {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.email-contactnumber-disclaimer {
  padding: 5px 10px;
  background-color: $colorDisabledGrey;
  font-size: 12px;
  margin: 15px 0;
  color: $colorInactiveGrey;
  border-radius: 4px;

  &.has-error {
    color: $colorAlertNegative;
  }
}

.location-container {
  padding: 5px 15px;
  .switch {
    height: 20px !important;
  }
}

.zipcode-wrapper {
  .loading {
    background-color: $colorWhite;
    background-image: url('../../assets/images/loading.gif');
    background-size: 25px 25px;
    background-position: right center;
    background-repeat: no-repeat;
  }
  .location-name {
    padding: 10px 0;
    font-weight: 100;
  }
}
#add-patient-step-one-form {
  .form-group {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

#add-patient-step-two-form {
  .form-group {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

.horizontal-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.horizontal-form-bottom {
  display: grid;
  grid-template-columns: 32% 32%;
  .date-field {
    width: 100%;
  }
  column-gap: 16px;
}

.form-two-body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  .step-two-form {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}

.step-one-form {
  display: flex;
  row-gap: 16px;
  flex-direction: column;
}
// .form-body{
//   display: flex;
//   flex-direction: column;
//   row-gap: 16px;
//   .form-group{
//     display: flex;
//     flex-direction: column;
//     row-gap: 8px;
//     margin: 0;

//     label{
//       text-transform: uppercase;
//     }
//     .required:after {
//       content:"*";
//       color: $colorAlertNegative;
//     }

//     .date-picker-field{
//       width: 270px;
//       padding: 8px;

//     }
//   }
//   .form-control{
//     border: 2px solid $colorDisabledGrey !important;
//     font-size: 14px !important;
//     font-weight: 400 !important;
//     color: $colorPrimary !important;
//     border-radius: 4px;
//   }
//   .inline-form{
//     flex-grow: 1;
//   }
//   .horizontal-form{
//     display: flex;
//     flex-direction: row;
//     column-gap: 8px;
//   }
//   .date-field{
//   }
// }

.step-3-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .data-information {
    display: grid;
    grid-template-columns: 60% 40%;

    .vertical-line {
      border-left: 1px solid $colorInactiveGrey;
      margin: -14px 40px 0 40px;
    }
    .info-title {
      font-size: 12px;
      font-weight: 500;
      color: $colorPrimaryDark;
    }
    .subject-information-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: 0 48px 0 8px;
      border-right: 0.5px solid $colorInactiveGrey;

      .info-columns {
        display: flex;
        flex-direction: column;
        // grid-template-columns: repeat(3,auto);
        row-gap: 25px;
        .info-column {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 24px;
          .info {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            min-width: 136px;
            .info-key {
              font-size: 12px;
              font-weight: 700;
              color: $colorInactiveDarkGrey;
            }
            .info-value {
              font-size: 16px;
              font-weight: 400;
              color: $colorBlack;
            }
          }
        }
      }
    }

    .study-information-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: 0 8px 0 48px;
      border-left: 0.5px solid $colorInactiveGrey;
      .info-columns {
        display: flex;
        row-gap: 25px;
        .info-column {
          display: grid;
          gap: 24px;
          .info {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            min-width: 136px;
            .info-key {
              font-size: 12px;
              font-weight: 700;
              color: $colorInactiveDarkGrey;
            }
            .info-value {
              font-size: 16px;
              font-weight: 400;
              color: $colorBlack;
            }
          }
        }
      }
    }
  }
}
