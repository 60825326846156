@import '/src/assets/stylesheets/variables.scss';

.patient-info-card {
  display: flex;
  flex-direction: column;
  flex: 2 1 0;
  min-height: 196px;
  background: $colorWhite;
  border: 1px solid $colorDisabledGrey;
  box-shadow: 0px 3px 6px rgba(4, 131, 223, 0.1);
  border-radius: 4px;
  flex-wrap: wrap;
  .header {
    display: flex;
    flex-direction: row;
    padding: 4px;
    border-bottom: 2px solid $colorDisabledGrey;
    align-items: center;
    height: 40px;
    .card-icon {
      padding-bottom: 2px;
    }
  }
  .body-content {
    display: flex;
    flex-direction: row;
    padding: 8px;
  }
}
.left-bar {
  border-left: 4px solid $colorPrimary;
  border-radius: 5px 0px 0px 5px;
}
