@import '/src/assets/stylesheets/variables.scss';
@import 'src/assets/stylesheets/mixins.scss';

#survey-tabs {
  .survey-container {
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    width: 100%;
    @media print {
      display: block;
    }
    .tabs-container {
      z-index: 1;
      height: calc(100vh - 367px);
      padding: 10px 0;
      .selected-survey-container {
        margin: 0 auto;
        padding: 10px;
        margin-bottom: 10px;
        margin-right: -2px;
        border: 2px solid $colorPrimary;
        border-right: none;
        background: $colorWhite;
        border-radius: 5px 0px 0px 5px;
        font-size: 16px;
        font-weight: 500;
        .icon-font-a-print:before {
          top: -2px;
          right: 12px;
        }
      }

      ul {
        height: calc(100vh - 370px);
        overflow-y: scroll;
        // border: 1px solid $colorDisabledGrey;
        width: calc(100% - 24px);
        margin-left: 8px;

        li {
          padding: 0;
          margin-bottom: 10px;

          a {
            padding: 10px;
            text-align: left;
            border: none;
            background: $colorWhite;
            color: $colorBlack;
            margin-bottom: 0;
            border: 1px solid $colorPrimary;
            border-radius: 5px;
            height: auto;

            &:focus {
              outline: none;
            }
          }

          &.active {
            a {
              border-color: $colorDisabledGrey;

              .survey-header div:first-child {
                // font-weight: 500;
              }
            }
          }

          &.first-view {
            a {
              border-color: $colorPrimary;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .tabs-content-container {
    z-index: 0;
    height: calc(100vh - 300px);
    padding: 20px 0;
    overflow-y: scroll;
    border-left: 2px solid $colorPrimary;

    @media print {
      overflow-y: visible !important;
      height: auto;
    }

    .tab-content {
      padding: 0;
      margin-top: 0;
      border-radius: 0;
      min-height: 100%;
      border: none;
      overflow-y: scroll;

      @media print {
        overflow: visible !important;
      }
    }

    .no-selected-survey-container {
      @include display-flex();
      @include flex-direction(column);
      @include flex-wrap(nowrap);
      @include justify-content(center);
      @include align-content(center);
      @include align-items(center);
      height: calc(100vh - 340px);
      div {
        @include flex-order(0);
        @include flex(0 0 auto);
        @include align-self(center);

        font-size: 14px;
        font-weight: 300;
      }
    }
  }

  .completed {
    .icon:before {
      font-size: 20px;
      left: 5px;
      color: $colorAlertPositive;
      top: 5px;
    }
  }
}

.edit-survey {
  margin-right: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid $colorPrimary;
  color: $colorPrimary;

  .edit-survey-button-icon {
    &.icon:before {
      font-size: 20px;
      right: 5px;
      top: -3px;
    }
  }
}
