@import 'src/assets/stylesheets/variables.scss';

.header-right {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  justify-content: flex-end;
  column-gap: 8px;

  .icon,
  .icon-font-a-print::before {
    font-size: 20px;
  }
  .icon-font-a-print::before {
    top: 0;
  }
}

.audit-trail-table {
  .data-table-row {
    th,
    td {
      white-space: normal !important;
      text-align: left !important;
      min-width: 100px;
      span {
        justify-content: flex-start !important;
      }
    }
  }
  .printed-by-text {
    margin: 24px 0px;
  }
  .show-only-in-print {
    visibility: hidden;
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .audit-trail-table,
  .data-table,
  .data-table-row,
  th,
  td {
    visibility: visible;
    .show-only-in-print {
      visibility: visible;
      display: block;
    }
  }
  .data-table {
    .data-table-row {
      th,
      td {
        max-width: 100px;
        min-width: 0px;
      }
      th {
        span {
          visibility: visible;
          color: $colorPrimaryDark !important;
          white-space: normal !important;
          justify-content: flex-start !important;
        }
        background-color: $colorPrimaryLight !important;
      }
      td {
        color: $colorInactiveDarkGrey !important;
        visibility: visible;
      }
    }
  }
}
