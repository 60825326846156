@font-face {
  font-family: 'icomoon';
  src: url('../fonts/audit-trail-icon-fonts/icomoon.eot?kk8305');
  src: url('../fonts/audit-trail-icon-fonts/icomoon.eot?kk8305#iefix')
      format('embedded-opentype'),
    url('../fonts/audit-trail-icon-fonts/icomoon.ttf?kk8305') format('truetype'),
    url('../fonts/audit-trail-icon-fonts/icomoon.woff?kk8305') format('woff'),
    url('../fonts/audit-trail-icon-fonts/icomoon.svg?kk8305#icomoon')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// [class^= "icon-featured_play_list"], [class*=" icon-featured_play_list"]
.icon-featured_play_list {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-featured_play_list:before {
  content: '\e900';
  color: #0483df;
}
