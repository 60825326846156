@import '/src/assets/stylesheets/variables.scss';

.team-information-container {
  //font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 8px auto;
  width: 100%;
}

.table-records {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  .title {
    color: $colorInactiveDarkGrey;
    // margin-bottom: 16px;
    @media screen and (max-width: 1024px) {
      height: 33px;
    }
    @media (orientation: landscape) {
      height: 33px;
    }
  }

  .column-body {
    // margin-bottom: 16px;
    // overflow: hidden;
    min-width: 168px;
    @media screen and (max-width: 1024px) {
      height: 33px;
    }
    @media (orientation: landscape) {
      height: 33px;
    }
  }

  .email-field {
    min-width: 180px;
  }
}

.done-button {
  margin: 8px 0;
}
