@import '/src/assets/stylesheets/_variables.scss';

.message-list-row {
  padding-left: 0 !important;
  white-space: pre-wrap;
  .content {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    &.provider {
      border-left: 2px solid $colorPrimary;
    }
    &.patient {
      border-left: 2px solid $colorPrimaryDark;
    }
  }
  .date {
    color: $colorInactiveDarkGrey;
    font-weight: 300;
  }
  .sent-on {
    font-weight: 100;
  }
  .dot:before {
    content: ' \2022 ';
    color: $colorDisabledGrey;
    margin-left: 2px;
    margin-right: 5px;
  }
  .margin-top-5 {
    margin-top: 5px;
  }
  .font-weight-300 {
    font-weight: 300;
  }
  .font-size-sm {
    font-size: 12px;
  }
  .attachments-container {
    margin: 0;
    .attachment-row {
      margin-left: 0 !important;
      margin-right: 0 !important;
      font-size: 12px;
      .attachment-icon {
        line-height: 10px;
        .icon:before {
          font-size: 12px;
          top: 7px;
        }
      }
    }
  }
}
