@charset "UTF-8";

@font-face {
  font-family: 'task-icons';
  src: url('assets/fonts/task-fonts/task-font-3.eot');
  src: url('assets/fonts/task-fonts/task-font-3.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/task-fonts/task-font-3.woff') format('woff'),
    url('assets/fonts/task-fonts/task-font-3.ttf') format('truetype'),
    url('assets/fonts/task-fonts/task-font-3.svg#task-font-3') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'task-icons' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-tasks']:before,
[class*=' icon-tasks']:before {
  font-family: 'task-icons' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tasks:before {
  content: '\61';
}
