@import 'assets/stylesheets/variables.scss';
.comment-container {
  border-bottom: 1px solid $colorDisabledGrey;
  padding: 16px 4px;
  display: flex;
  flex-direction: row;

  .comment-bubble-icon {
    color: $colorPrimary;
    border-radius: 0px 0px 8px 8px;
  }

  .comment-time-container {
    font-size: 12px;
    color: $colorInactiveDarkGrey;
  }

  .comment-card-right-pane {
    // padding: 0px 0px 16px 16px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 0px 16px;
    .comment-text {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .created-by-info {
    margin-bottom: 5px;
    line-height: 36px;
    .log-icon {
      padding-right: 5px;
      color: $colorDisabledGrey;
    }
    .created-by-time {
      color: $colorInactiveGrey;
      padding-left: 5px;
      font-weight: 300;
    }
    .respond-on {
      color: $colorPrimaryDark;
    }
  }
  .edit-comment-label {
    padding: 5px;
    color: $colorPrimary;
    font-weight: 500;
  }

  .comment-time {
    color: $colorInactiveGrey;
    padding-left: 5px;
    font-weight: 300;
  }

  .author-tag {
    line-height: inherit;
    .comment-author-tag {
      line-height: 36px;
      padding-right: 10px;
      border-right: 1px solid $colorDisabledGrey;
    }
  }
}
