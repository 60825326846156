@import '/src/assets/stylesheets/variables.scss';

.confirmation-modal-body {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: $colorPrimary;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.highlighted-text {
  color: $colorPrimary;
}

:export {
  colorWhite: $colorWhite;
  colorAlertNeutralDark: $colorAlertNeutralDark;
  colorPrimary: $colorPrimary;
  colorInactiveDarkGrey: $colorInactiveDarkGrey;
}

.confirmation-modal-password-wrapper {
  z-index: 0;

  .MuiFormControl-root
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl
    fieldset.MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }

  .MuiIconButton-root {
    padding: 6px;
  }
}
