@import '../../../assets//stylesheets/variables';
@import '../common/styles.scss';
//   MUI TextField Base CSS Class

.MuiFormControl-root {
  @include set_border_and_label_for_inactive_state(
    $colorInactiveDarkGrey,
    $colorInactiveGrey,
    2px
  );
  @include set_border_and_label_for_active_state(
    $colorPrimary,
    $colorPrimary,
    2px
  );
  @include set_border_and_label_for_disabled_state(
    $colorInactiveGrey,
    $colorInactiveGrey,
    2px
  );
  @include set_border_and_label_for_error_state(
    $colorInactiveGrey,
    $colorInactiveGrey,
    2px
  );
}

.input-box-helper-text {
  color: $colorAlertNegative;
  font-size: 12px;
  margin-left: 15px;
  margin-top: 1px;
}

.input-box-error-icon {
  color: $colorAlertNegative;
}

// .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl fieldset.MuiOutlinedInput-notchedOutline
