@import '/src/assets/stylesheets/_variables.scss';
.form-group {
  textarea {
    resize: none;
  }
  .character-limit {
    align-self: flex-end;
    color: $colorInactiveDarkGrey;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

.re-auth-form {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
