@import '../../assets/stylesheets/variables';

.lookup {
  width: 100% !important;

  // margin-top: 15px !important;

  .navbar-left {
    margin: 0 !important;
  }
  // &.navbar-left {
  //   float: right !important;
  // }

  .form-group {
    vertical-align: top;

    .help-block {
      font-size: 12px;
      font-weight: 100;
    }
  }
  .lookup-form {
    display: flex !important;
    flex-direction: row !important;
    margin-left: auto;
    margin-right: auto;
    .form-control {
      border-radius: 4px 0px 0px 4px !important;
    }
  }
  input::-webkit-input-placeholder {
    color: $colorInactiveDarkGrey;
    font-size: 14px;
    font-weight: 400;
  }
  input:-moz-placeholder {
    color: $colorInactiveDarkGrey;
    font-size: 14px;
    font-weight: 400;
  }

  input {
    border: 1px solid $colorInactiveGrey;
    background: $colorWhite;
    border-radius: 0px;
    margin: 0;
    height: 40px;
    font-size: 14px;
    min-width: 260px;
    width: calc(100% - 80px) !important;
    padding: 0 19px;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: red;
      opacity: 1; /* Firefox */
    }

    @media screen and (min-width: 821px) and (max-width: 1024px) {
      min-width: 165px;
      font-size: 12px;
    }
    @media screen and (max-width: 820px) {
      min-width: 105px;
      font-size: 12px;
    }
  }

  .btn-primary {
    background: $colorPrimaryGradient;
    border: 1px solid $colorPrimary !important;
    border-image: $colorPrimaryGradient;
    vertical-align: top;
    height: 32px;
    line-height: 20px;
    padding: 5px 12px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 0px 5px 5px 0px;
    margin-left: -1px;

    &:hover {
      border: 1px solid $colorPrimary !important;
    }
  }
}

// @media screen and (max-width: 875px) {
.navbar-form {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
