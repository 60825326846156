@import '../../assets/stylesheets/variables.scss';
.attachment-view {
  img {
    max-width: 100%;
  }
}

.select-attachment {
  label {
    border: 1px dashed $colorPrimary;
    width: 100%;
    padding: 15px 10px;
    text-align: center;
    color: $colorPrimary;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 300;
    border-radius: 5px;
    input {
      display: none;
    }
  }
}

.message-container {
  font-weight: 300;
  font-size: 12px;
  padding-top: 10px;
  color: $colorPrimaryDark;
}

.select-attachment-disabled {
  label {
    border: 1px dashed $colorDisabledGrey;
    width: 100%;
    padding: 15px 10px;
    text-align: center;
    color: $colorDisabledGrey;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 300;
    border-radius: 5px;
    input {
      display: none;
    }
  }
}

.message-container {
  margin-top: -10px;
  margin-bottom: -5px;
  font-weight: 300;
  font-size: 12px;
}

.file-support-msg-container {
  padding: 10px 0;
  color: $colorAlertNegative;
  display: block;
}

.selected-file-name-container {
  .selected-file-name {
    margin: 0;
  }
  .selected {
    color: green;
    margin: 0 5px;
    &:before {
      font-size: 14px;
    }
  }
  .progressbar {
    margin: 10px 0px;
  }
}

.add-attachment-row {
  margin-top: 10px;
  width: auto;
  float: left;
  &:last-child {
    border-right: 0;
    margin-left: 0px;
  }
  .add-attachment-name {
    margin-left: 10px;
    color: $colorPrimary;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 240px;
    display: inline-block;
    @media screen and (max-width: 572px) {
      max-width: 220px;
    }
    @media screen and (max-width: 320px) {
      max-width: 175px;
    }
  }

  .add-attachment-icon {
    .icon::before {
      top: 7px;
    }
  }
}

.button-done {
  margin-top: 20px;
}

.add-attachment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $colorPrimary;
  border-radius: 15px;
  color: $colorPrimary;
  margin-right: 10px;
  line-height: 24px;
  font-weight: 300;
  > span {
    margin-left: 10px;
  }
  .add-attachment-remove-icon {
    float: right;
    margin-right: 8px;
    &:before {
      font-size: 12px;
      top: 0;
    }
  }
}

.response-add-attachment-modal {
  .attachment-container {
    margin-bottom: 30px;
  }
}
