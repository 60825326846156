@import 'assets/stylesheets/variables.scss';

.home-page-container {
  // margin: 0 15px;
  // padding-inline: 8px;
  display: flex;
  flex-direction: column;

  .horizontal-line {
    border: 1px solid $colorInactiveGrey;
    margin: 0 -8px;
    &:last-child {
      border: 0;
    }
  }
  .dashboard-navigation-container {
    border: 1px solid $colorPrimaryDark;
    // margin-bottom: 16px;
    border-radius: 4px;
    // margin-top: -5px;

    .dashboard-navigation-item {
      height: 40px;
      border-right: 1px solid $colorPrimaryDark;
      color: $colorPrimaryDark;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
      }

      @media screen and (min-width: 769px) and (max-width: 1048px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      // 	&:hover {
      // 	@keyframes exp {
      //       from {background-color: rgba($colorSecondaryDark, 0.5);}
      // 		to {background-color: $colorSecondaryDark;}
      //     }

      //     animation-name: exp;
      // 	animation-duration: 1s;
      // 	background-color: $colorSecondaryDark;
      // 	color: $colorPrimaryDark;
      // }
      &:active {
        // @keyframes exp {
        // 	from {background-color: rgba($colorPrimaryDark, 0.5);}
        // 	  to {background-color: $colorPrimaryDark ,0.2}
        //   }

        //   animation-name: exp;
        //   animation-duration: 1s;
        //   background-color: $colorPrimaryDark;
        //   color: $colorWhite;
      }

      &:last-child {
        border-right: none;
      }
      &.disabled {
        color: $colorDisabledGrey;
        cursor: default;
      }

      .icon {
        position: relative;
        top: 2px;
      }

      .manage-patient-advocate-icon:before {
        top: 6px;
      }
    }
  }
  .dashboard-body-containers {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(540px, 1fr));
    column-gap: 32px;
    row-gap: 32px;
    background-color: $colorSecondary;
    padding: 40px;

    @media screen and (min-width: 1191px) {
      height: calc(100% - 60px);
    }
    .container-title-row {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;
      .container-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 18px;
        color: $colorPrimaryDark;
        .add-custom-filters-btn {
          font-size: 12px;
          font-weight: 400;
          color: $colorWhite;
          position: absolute;
          right: 20px;
          top: 15px;
          cursor: pointer;

          &:hover {
            background-color: $colorPrimary;
            border: none;
          }

          .icon {
            position: relative;
            top: 1px;

            &:before {
              font-size: 20px;
            }
          }
        }
      }
      .create-list-text {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
          font-size: 15px;
        }
      }
    }
    .no-visits-message {
      padding: 24px 0 48px 0;
      color: $colorInactiveGrey;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }
    // flex-direction: row;
    // margin-top:16px;
    // height: calc(100vh - 70px - 90px);

    // .tip {
    //   top: 8px;
    //   left: 26px;
    //   transform: rotate(-90deg);
    // }

    // .tooltip-content {
    //   width: 200px;
    //   left: 35px;
    //   top: -10px;
    // }

    // .card .icons-container .item {
    //   .tip {
    //     top: 24px;
    // 	left: 7px;
    // 	transform: rotate(0deg);
    //   }

    //   .tooltip-content {
    // 	width: 170px;
    // 	top: 30px;
    // 	left: 0;
    //   }
    // }
  }
  .patient-followup-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    .calendar-area {
      height: 217px;
      padding: 24px 24px 0 24px;
    }
    .calendar-horizontal-line {
      border: 1px solid $colorInactiveGrey;
      margin: 16px -8px;
      &:last-child {
        border: 0;
      }
    }
    // background-color: $colorSecondary;
    // border: 1px solid $colorDisabledGrey;
    // border-radius: 4px;
    // width: calc(50% - 10px);
    // height: calc(100vh - 70px - 90px);
    // overflow-y: clip;
    // padding-bottom: 20px;
    // float: left;

    // 	@media screen and (max-width: 768px){
    // 		width: 100%;
    // 		height: calc(100% - 150px);
    // 	}

    // 	.glyphicon {
    // 		margin:	0 20px;
    // 	}
    // 	@media print {
    //   		width: 100%;
    //   		height: 80%;
    // 	}
    .patient-card-container {
      display: flex;
      flex-direction: column;
      // height: calc(100% - 12px - 60px - 28px - 220px - 28px);
      overflow: auto;
      margin-top: 10px;
      font-size: 12px;
      line-height: 18px;
      overflow: auto;
    }
    // 	.patient-card-container {
    // 	background: $colorSecondary;
    //     padding: 5px 0px 10px 0px;
    //     height: calc(100% - 150px - 55px - 30px);
    //     overflow: auto;
    //     margin-top: 10px;
    //     font-size: 12px;
    //     line-height: 18px;

    // 	& > :first-child > .to-click {
    // 		margin-top: 0;
    // 	}

    //     .card:hover {
    //     	background: $colorWhite;
    //     }

    // 	.row.zero-margin {
    // 		margin-inline: 0;
    // 	}

    // 	.card {
    //       @media screen and (max-width: 1024px) {
    // 	    padding-left: 10px;
    // 	  }
    // 	}

    // 	}

    // 	.icon-font-a-stethoscope:before {
    //     font-size: 15px;
    //     top: 2px;
    // 	}
    // 	.providers-selector-pill {
    //   .providers-selector-pill--select {
    //     border: 1px solid $colorPrimary;
    //     border-radius: 20px;
    //     background-color: $colorPrimary;
    //     color: $colorWhite;
    //     font-size: 16px;
    //     .multi-select__control {
    //       @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //         display: block;
    //       }
    //       border: none;
    //       background-color: transparent;
    //       cursor: pointer;
    //       border-radius: 20px !important;
    // 	//   .multi-select__control--is-focused.multi-select__control--menu-is-open{
    // 	// 	border-color: 1px solid red !important;
    // 	//   }
    //       .multi-select__placeholder {
    //         @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //           /* IE10+ CSS styles go here */
    //           display: inline;
    //         }
    //         max-width: calc(100% - 8px);
    //         overflow: hidden;
    //         white-space: nowrap;
    //         text-overflow: ellipsis;
    //         font-size: 15px;
    //         font-weight: 300;
    //         color: inherit;
    //         left: 0;
    //         right: 0;
    //         margin-left: 8px;
    //         margin-top: 0px;
    //         transform: none;
    //         text-align: center;
    //       }
    //       .multi-select__indicators {
    //         @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //           display: inline-block;
    //         }
    //       }
    //       .multi-select__indicator {
    //         @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //           position: relative;
    //           top: 3px;
    //         }
    //         color: inherit;
    //         transition: none;
    //       }
    //     }
    //     .multi-select__indicator-separator {
    //       display: none;
    //     }
    //     .multi-select__menu {
    //       color: $colorBlack;
    //       width: 100%;
    //       z-index: 9;
    //       .multi-select__option.multi-select__option--is-selected {
    //         label {
    //           span {
    //             &:nth-of-type(2) {
    //               font-weight: 400;
    //             }
    //           }
    //         }
    //       }
    //       .multi-select__option {
    //         color: $colorInactiveGrey;
    //         cursor: pointer;
    //         input, label {
    //           cursor: pointer;
    //         }
    //         label {
    //           display: flex;
    //           align-items: center;
    //           margin-left: 0 !important;
    //           span {
    //             &:first-of-type {
    //               color: $colorPrimary;
    //               &:before {
    //                 display: flex;
    //                 font-size: 17px;
    //               }
    //             }
    //             &:nth-of-type(2) {
    //               font-weight: 300;
    //               margin-left: 7px;
    //             }
    //           }
    //         }
    //       }
    //       footer {
    //         display: flex;
    //         justify-content: flex-end;
    //         padding: 8px 10px;
    //         border-top: 1px solid $colorPrimary;

    //         .btn-primary {
    //           border-radius: 5px;
    //           height: auto;
    //           padding: 2px 20px;
    //           font-size: 14px;
    //           margin-right: 0;
    //         }
    //       }
    //     }
    //   }
    //   .providers-selector-pill--select.has-selected-items {
    //     background-color: $colorPrimary;
    //     color:$colorWhite;
    //   }
    //   .multi-select__value-container {
    //     @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //       /* IE10+ CSS styles go here */
    //       display: inline-block;
    //     }
    //     padding: 0 0 0 8px
    //   }
    //   .value-container--value-outer {
    //     @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //       display: inline;
    //     }
    //     justify-content: center;
    //     color: inherit;
    //     .value-container--value-label {
    //       flex: none;
    //     }
    //   }
    // }
  }

  .track-patients-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 16px;
    .track-patient-filter-container {
      display: flex;
      flex-direction: column;
      .title-area {
        padding: 24px 24px 0 24px;
      }
      .track-patient-filter-title {
        padding-bottom: 16px;
      }
      .track-patients-results-container {
      }
    }

    .track-patient-title {
      font-size: 13px;
      font-weight: 600;
      color: $colorInactiveDarkGrey;
      display: flex;
      border-bottom: 1px solid $colorDisabledGrey;
      justify-content: space-between;
      text-transform: uppercase;
      padding: 12px 48px 12px 24px;
    }

    // margin-left: 10px;
    // float: right;
    // height: calc(100vh - 70px - 90px);

    // @media screen and (max-width: 768px){
    // 	width: 100%;
    // 	margin-top: 20px;
    // 	margin-bottom: 20px;
    // 	margin-left: 0px;
    // }

    // .track-patient-filter-container {
    // 	border: 1px solid $colorDisabledGrey;
    // 	border-radius: 4px;
    // 	height: calc(100vh - 70px - 90px);

    // 	.track-patients-results-container {
    // 		overflow: auto;
    // 		height: calc(100vh - 70px - 90px - 70px);

    // 		.default, .custom {
    // 			.icon:before {
    // 				color: $colorPrimaryDark;
    // 			}
    // 		}

    // 		 .disabled {
    // 			.icon:before {
    // 				color: $colorDisabledGrey;
    // 			}
    // 		}
    // 		&.with-back-button{
    // 			height: calc(100vh - 70px - 90px - 70px - 45px);
    // 		}
    // 	}
    // }

    // .add-patient-btn-container {
    // 	width: 48%;
    // 	float: left;
    // }

    // .survey-btn-container {
    // 	width: 48%;
    // 	float: right;
    // }
  }

  .filter-back-button-container {
    padding-bottom: 25px;
    padding-left: 20px;
    padding-top: 20px;
    position: relative;

    .filters-back-btn {
      font-size: 16px;
      font-weight: 300;
      color: $colorPrimary;
      position: absolute;
      top: 5px;
      bottom: 10px;
      cursor: pointer;

      &:hover {
        color: $colorPrimaryDark;
      }

      .icon {
        position: relative;
        top: 1px;

        &:before {
          font-size: 18px;
        }
      }
    }
  }

  .no-padding {
    padding: 0px;
  }

  .search-container {
    width: 48%;
    margin: 0 1%;
    float: left;
    position: relative;
    margin-bottom: 20px;
  }

  .button-container {
    width: 48%;
    margin: 0 1%;
    float: left;
  }
}

.procedure-select,
.location-select {
  // border: none;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid $colorDisabledGrey;
  margin-bottom: 10px;
  font-size: 14px;
}

.procedure-select:focus,
.location-select:focus {
  border-color: $colorDisabledGrey;
  box-shadow: none;
}

.procedure-date-info {
  padding: 5px 10px;
  background-color: $colorDisabledGrey;
  font-size: 12px;
  margin: 15px 0;
  color: $colorInactiveGrey;
  border-radius: 4px;
}

.add-patient-modal {
  min-height: 320px;

  .modal-subheader-content {
    background: $colorPrimaryLight;
    margin-top: -21px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    padding-left: 5px;
    line-height: 40px;

    @media screen and (max-width: 767px) {
      margin-top: -15px;
      margin-left: -15px;
      margin-right: -15px;
    }

    .steps-count {
      display: inline-block;
      width: 25px;
      line-height: 23px;
      border: 1px solid $colorPrimary;
      text-align: center;
      border-radius: 50%;
    }

    .active-step-count {
      background: $colorSecondaryDark;
    }

    .separation {
      margin: 0px 3px;
    }
  }

  .icon-font-a-check-mark:before,
  .icon-font-a-check-box:before {
    color: $colorPrimary;
  }
  .check-box-label {
    position: relative;
    top: -4px;
    left: 4px;
    font-size: 12px;
  }
}
.show-only-in-print {
  visibility: hidden;
  display: none;
}
@media print {
  body * {
    visibility: hidden;
  }
  .print-overflow-visible {
    overflow: visible;
  }
  .hide-in-print {
    display: none;
    visibility: hidden;
  }
  .img-cell {
    width: 90px;
    height: 100px;
    padding: 10px 0px 10px 10px;
  }
  img.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
  }
  .show-only-in-print {
    visibility: visible;
    display: inline;
  }
  .all-border {
    border: 1px solid $colorDisabledGrey;
  }
  .show-only-in-print.patient-procedure-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible;
    overflow: visible !important;
    .hide-in-print {
      display: none;
    }
  }
  .logo-in-print {
    padding: 10px 0px 10px 10px;
  }
  .index-in-print {
    vertical-align: middle;
    line-height: 90px;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    align-content: flex-end;
  }
  .patient-card-in-print {
    padding: 0;
  }
  .topborder {
    border-top: 1px solid $colorDisabledGrey;
    margin: 10px 12px;
  }
  .icons-container {
    & > div[role='button'] {
      width: 100%;
    }
  }
}
