@font-face {
  font-family: 'stars';
  src: url('../fonts/star-fonts/stars.eot');
  src: url('../fonts/star-fonts/stars.eot#iefix') format('embedded-opentype'),
    url('../fonts/star-fonts/stars.ttf') format('truetype'),
    url('../fonts/star-fonts/stars.woff') format('woff'),
    url('../fonts/star-fonts/stars.svg#stars') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'stars' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account-circle:before {
  content: '\e900';
  color: #7f8589;
}
.icon-add-circle:before {
  content: '\e901';
}
.icon-add:before {
  content: '\e903';
  color: #7f8589;
}
.icon-arrow-back:before {
  content: '\e904';
}
.icon-arrow-back-iOS:before {
  content: '\e905';
  color: #7f8589;
}
.icon-drop-down-arrow:before {
  content: '\e906';
  color: #575558;
}
.icon-arrow-forward:before {
  content: '\e907';
}
.icon-arrow-forward-iOS:before {
  content: '\e908';
  color: #7f8589;
}
.icon-attachment-h:before {
  content: '\e909';
  color: #7f8589;
}
.icon-attachment-v:before {
  content: '\e90a';
  color: #7f8589;
}
.icon-call:before {
  content: '\e90b';
  color: #7f8589;
}
.icon-cancel-circle:before {
  content: '\e90c';
  color: #7f8589;
}
.icon-cancel-circle-fill:before {
  content: '\e90d';
  color: #7f8589;
}
.icon-checkbox:before {
  content: '\e90f';
  color: #7f8589;
}
.icon-checkbox-outline:before {
  content: '\e910';
  color: #7f8589;
}
.icon-check-circle:before {
  content: '\e911';
  color: #7f8589;
}
.icon-checklist:before {
  content: '\e913';
}
.icon-chevron-back:before {
  content: '\e914';
  color: #7f8589;
}
.icon-chevron-forward:before {
  content: '\e915';
  color: #7f8589;
}
.icon-clear-cross:before {
  content: '\e916';
  color: #7f8589;
}
.icon-delete:before {
  content: '\e917';
  color: #665e57;
}
.icon-notes:before {
  content: '\e918';
  color: #575558;
}
.icon-directions:before {
  content: '\e919';
}
.icon-team:before {
  content: '\e91a';
  color: #575558;
}
.icon-draw:before {
  content: '\e91c';
}
.icon-edit:before {
  content: '\e91d';
  color: #7f8589;
}
.icon-error_outline_black_24dp-1:before {
  content: '\e91e';
  color: #7f8589;
}
.icon-calendar:before {
  content: '\e91f';
  color: #7f8589;
}
.icon-expand-less:before {
  content: '\e920';
  color: #7f8589;
}
.icon-expand-more:before {
  content: '\e921';
  color: #7f8589;
}
.icon-details:before {
  content: '\e922';
  color: #0483df;
}
.icon-enlarge:before {
  content: '\e924';
  color: #7f8589;
}
.icon-phase:before {
  content: '\e925';
  color: #004b87;
}
.icon-message-circle .path1:before {
  content: '\e927';
  color: rgb(255, 255, 255);
}
.icon-message-circle .path2:before {
  content: '\e928';
  margin-left: -1em;
  color: rgb(138, 110, 147);
}
.icon-call-circle .path1:before {
  content: '\e929';
  color: rgb(255, 255, 255);
}
.icon-call-circle .path2:before {
  content: '\e92a';
  margin-left: -1em;
  color: rgb(138, 110, 147);
}
.icon-lock-circle .path1:before {
  content: '\e92b';
  color: rgb(255, 255, 255);
}
.icon-lock-circle .path2:before {
  content: '\e92c';
  margin-left: -1em;
  color: rgb(138, 110, 147);
}
.icon-help:before {
  content: '\e92d';
}
.icon-image:before {
  content: '\e92e';
  color: #575558;
}
.icon-key:before {
  content: '\e92f';
  color: #575558;
}
.icon-logout:before {
  content: '\e930';
  color: #665e57;
}
.icon-comment-add:before {
  content: '\e931';
  color: #7f8589;
}
.icon-message:before {
  content: '\e932';
  color: #7f8589;
}
.icon-notification-bubble .path1:before {
  content: '\e933';
  color: rgb(219, 84, 97);
}
.icon-notification-bubble .path2:before {
  content: '\e934';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-open-outside-app:before {
  content: '\e935';
  color: #7f8589;
}
.icon-person:before {
  content: '\e936';
  color: #7f8589;
}
.icon-camera:before {
  content: '\e937';
  color: #7f8589;
}
.icon-camera-outline:before {
  content: '\e938';
  color: #575558;
}
.icon-radio-button-outine:before {
  content: '\e939';
  color: #7f8589;
}
.icon-remove:before {
  content: '\e93a';
  color: #7f8589;
}
.icon-schedule:before {
  content: '\e93b';
  color: #7f8589;
}
.icon-select-checkbox:before {
  content: '\e93c';
  color: #7f8589;
}
.icon-send:before {
  content: '\e93e';
}
.icon-settings:before {
  content: '\e93f';
  color: #575558;
}
.icon-thumb-up:before {
  content: '\e941';
  color: #7f8589;
}
.icon-visibility:before {
  content: '\e943';
}
.icon-visibility-off:before {
  content: '\e944';
  color: #7f8589;
}
