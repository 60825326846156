@import 'assets/stylesheets/variables.scss';

.communication-log-list {
  // margin: 0 20px;
}
.communication-list-row {
  border-bottom: 1px solid $colorDisabledGrey;
  margin: 0;
  padding: 10px 0px;

  .communication-title {
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
  }

  .new_note_indicator {
    color: $colorAlertPositive;
  }

  .new_activity_indicator {
    color: $colorAlertNegative;
    font-size: 0.25em;
    display: block;
    position: absolute;
    top: 0.75em;
    right: -0.25em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    border-radius: 50%;
    text-align: center;
  }

  .default-log-icon {
    color: $colorDisabledGrey;
  }

  // .communication-log-list-chevron{
  //   position: absolute;
  //   right: 10px;
  //   top: calc(50% - 15px);
  //   color:$colorDisabledGrey;
  // }

  .icon-container {
    position: absolute;
    top: 10px;
    left: 0;
  }

  .communication-log-details-container {
    margin-left: 10px;
  }

  .communication-description {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: $colorInactiveGrey;
    word-break: break-word;
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
    white-space: pre-wrap;
  }
}

.notes {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  border: 1px solid $colorDisabledGrey;
  border-radius: 6px;
  padding: 16px;

  .note-status-icon {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
  }

  .note-unread-icon {
    color: #e76767;
  }

  .note-read-icon {
    color: #e5eef1;
  }
  .notes-pane1 {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    font-size: 12px;
    width: 144px;
    // padding: 0 8px 8px 0;

    .notes-pane1-item-icon {
      color: $colorInactiveGrey;
    }
    .notes-pane1-item-text {
      // padding-left: 6px;
      color: $colorInactiveGrey;
    }
    .non-zero-count {
      color: $colorInactiveDarkGrey;
    }
  }
  .notes-pane2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 145px);
    padding: 0px 8px;

    .communication-details-container {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      overflow-wrap: anywhere;
    }

    .communication-log-list-chevron {
      color: #323232;
      align-self: center;
      font-size: 24px;
    }
    .communication-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
    }
    .communication-description {
      font-size: 14px;
      font-weight: 400;
      white-space: pre-wrap;
    }
  }
  .vertical-line {
    border: 1px solid $colorDisabledGrey;
  }
}
