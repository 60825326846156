@import '/src/assets/stylesheets/variables.scss';

.landing-page-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @media screen and (max-width: 480px) {
    row-gap: 8px;
  }
  .buttons-seperator {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    justify-items: center;
    .line {
      width: 100%;
      hr {
        margin-top: 18px;
        margin-bottom: 0px;
        border: 0;
        border-top: 1px solid $colorInactiveGrey;
      }
    }
    .or-text {
      font-weight: 400;
      font-size: 24px;
    }
  }
}
