@import 'assets/stylesheets/variables.scss';

.task-title {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 0px;
  line-height: 30px;

  .critical-task-label {
    font-weight: 100;
  }
}

.task-validity-text {
  font-size: 14px;
  font-weight: 500;
  color: $colorInactiveGrey;
}

.task-card {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $colorDisabledGrey;

  &:last-child {
    border: 0;
  }

  .task-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0px;
    column-gap: 72px;

    .task-card-pane {
      display: flex;
      flex-direction: row;
      column-gap: 16px;

      .background-app-green {
        background-color: $colorAlertPositive;
      }

      .background-app-orange {
        background-color: $colorAlertNeutral;
      }

      .background-app-grey {
        background-color: $colorInactiveGrey;
      }

      .background-app-red {
        background-color: $colorAlertNegative;
      }

      .background-app-light-grey {
        background-color: $colorDisabledGrey;
      }

      .expiring-task {
        .icon {
          color: $colorInactiveDarkGrey;
        }
      }

      .task-body {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }
    }

    .task-card-buttons {
      align-self: center;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
  }

  .icon-circle-body {
    .icon-circle {
      .icon:before {
        color: $colorWhite;
        font-size: 16px;
        top: 0px;
      }
    }
  }
}
