@charset "UTF-8";

@font-face {
  font-family: 'mms-font-b';
  src: url('../fonts/mms-font/mms-font-b.eot') format('eot');
  src: url('../fonts/mms-font/mms-font-b.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/mms-font/mms-font-b.woff') format('woff'),
    url('../fonts/mms-font/mms-font-b.ttf') format('truetype'),
    url('../fonts/mms-font/mms-font-b.svg#mms-font-b') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'mms-font-b' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  position: relative;
  top: 4px;
}

[class^='icon-font-b']:before,
[class*=' icon-font-b']:before {
  font-family: 'mms-font-b' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  position: relative;
  top: 4px;
}

.icon-font-b-artboard:before {
  content: '\61';
}
.icon-font-b-artboard-copy:before {
  content: '\62';
}
.icon-font-b-expiring-task:before {
  content: '\63';
}
