@import 'assets/stylesheets/variables.scss';

.created-by-container {
  font-size: 12px;
  // margin-top: 5px;
  .created-by-label {
    color: $colorInactiveDarkGrey;
  }
  .created-by-time {
    color: $colorInactiveDarkGrey;
    font-weight: 300;
  }
  .communicaton-shared-label {
    padding-left: 5px;
  }
  .icon:before {
    font-size: 20px;
    top: 5px;
    left: 5px;
  }
  .attachment-icon {
    margin-left: 5px;
    &.icon:before {
      font-size: 18px;
    }
  }
  .attachment_count_lable {
    margin-left: 8px;
    font-size: 12px;
  }
}
