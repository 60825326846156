@import '../../assets/stylesheets/variables';

:export {
  primaryBtnBackground: $colorPrimaryGradient;
  primaryBtnBorderRadius: 4px;
  primaryBtnTextWeight: $font-weight-regular;
  primaryBtnTextSize: $font-size-xxs;
  primaryBtnLineHeight: 16px;
  letterSpacing: 0.15px;
  primaryBtnTextColor: $colorWhite;
  primaryBtnBoxShadow: 0px 4px 4px rgba(138, 110, 147, 0.5);
  primaryBtnBackgroundActive: $colorPrimaryDark;
  primaryBtnDisabledBackground: $colorDisabledGrey;
  primaryBtnDisabledTextColor: $colorInactiveGrey;
  colorWhite: $colorWhite;

  secondaryBtnBackground: $colorWhite;
  secondaryBtnBorder: 1px solid $colorAlertNegative;
  secondaryBtnBorderRadius: 4px;
  secondaryBtnTextWeight: $font-weight-regular;
  secondaryBtnTextSize: $font-size-xxs;
  secondaryBtnLineHeight: 16px;
  letterSpacing: 0.15px;
  secondaryBtnTextColor: $colorPrimaryDark;
  secondaryBtnBoxShadow: 0px 4px 4px rgba(138, 110, 147, 0.5);
  secondaryBtnBackgroundActive: $colorPrimary;
  secondaryBtnTextColorActive: $colorWhite;
  secondaryBtnBorderDisabled: 1px solid $colorDisabledGrey;
  secondaryBtnTextColorDisabled: $colorInactiveGrey;

  ternaryBtnBackground: $colorAlertNegative;
  ternaryBtnBorderRadius: 4px;
  ternaryBtnTextWeight: $font-weight-regular;
  ternaryBtnTextSize: $font-size-xxs;
  ternaryBtnLineHeight: 16px;
  letterSpacing: 0.15px;
  ternaryBtnTextColor: $colorWhite;
  ternaryBtnBackgroundHover: #c14d59;
  ternaryBtnBackgroundActive: #87000d;
  ternaryBtnDisabledTextColor: $colorInactiveGrey;
  ternaryBtnDisabledBackground: $colorDisabledGrey;

  inputBoxBorder: 2px solid $colorAlertNegative;
  inputBoxBorderRadius: 4px;
  inputBoxBorderColor: $colorInactiveGrey;
  inputBoxTextFontWeight: $font-weight-regular;
  inputBoxTextFontSize: $font-size-xxs;
  inputBoxLineHeight: 20px;
  inputBoxTextColorActive: $colorPrimaryDark;
  inputBoxBorderColorActive: $colorSecondaryDark;
  inputBoxTextColorDisabled: $colorPrimaryDark;
  inputBoxBorderColorDisabled: $colorSecondaryDark;
  inputBoxTextColorError: $colorPrimaryDark;
  inputBoxBorderColorError: $colorAlertNegative;

  colorPrimaryDark: $colorPrimaryDark;
  colorSecondaryDark: $colorSecondaryDark;
  colorInactiveGrey: $colorInactiveGrey;
  colorAlertNegative: $colorAlertNegative;
  colorPrimary: $colorPrimary;
  colorSecondary: $colorSecondary;
  colorPrimaryDark: $colorPrimaryDark;
  colorInactiveDarkGrey: $colorInactiveDarkGrey;
  colorAlertPositive: $colorAlertPositive;
  colorDisabledGrey: $colorDisabledGrey;
  colorAlertNeutral: $colorAlertNeutral;
}

.modal-header-content {
  display: flex;
  justify-content: flex-end;
  .MuiButtonBase-root.MuiIconButton-root {
    background-color: $colorDisabledGrey;
    width: 24px;
    height: 24px;
  }
  .MuiSvgIcon-root {
    font-size: 10px !important;
    color: $colorPrimary;
    width: 15px;
    height: 15px;
    stroke: $colorPrimary;
    stroke-width: 2px;
  }
}
.modal-details {
  display: flex;
  flex-direction: column;
  padding: 0 30px 26px 30px;
  row-gap: 24px;
  .modal-content {
    box-shadow: none !important;
    display: flex;
    gap: 16px;
    align-items: center;
    flex-direction: column;
    .modal-image {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .modal-with-no-icon {
      justify-content: center;
    }

    .modal-with-justified-content {
      flex-direction: column;
      gap: 24px;
    }
    .modal-text-and-icon {
      box-shadow: none !important;
      display: flex;
      column-gap: 24px;
      justify-content: flex-start;
      width: 100%;
      .modal-text {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .modal-form {
      width: 100%;
    }
  }

  .modal-content-header {
    color: $colorInactiveDarkGrey;
    font-size: 20px;
  }
  .modal-buttons {
    display: flex;
    column-gap: 24px;
    width: 100%;
  }
  .modal-buttons-stack {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

// styling for FormModal
.formmodal-header-content {
  display: flex;
  justify-content: space-between;
  height: 30px;
  .formmodal-content-title {
    color: $colorBlack;
    font-size: 20px;
  }
  .MuiButtonBase-root.MuiIconButton-root {
    background-color: $colorDisabledGrey;
    width: 24px;
    height: 24px;
  }
  .MuiSvgIcon-root {
    font-size: 10px !important;
    color: $colorPrimary;
    width: 15px;
    height: 15px;
    stroke: $colorPrimary;
    stroke-width: 2px;
  }
}

.formmodal-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 30px);
  flex: 1;
}
.formmodal-details {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex: 1;
  // height: calc( 100% - 30px);
  .formmodal-content {
    box-shadow: none !important;
    display: flex;
    gap: 16px;
    align-items: center;
    flex-direction: column;
    .modal-image {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .modal-with-no-icon {
      justify-content: center;
    }

    .modal-with-justified-content {
      flex-direction: column;
      gap: 24px;
    }
    .modal-text-and-icon {
      box-shadow: none !important;
      display: flex;
      column-gap: 24px;
      justify-content: flex-start;
      width: 100%;
      .modal-text {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .formmodal-subheader {
      background-color: $colorPrimaryLight;
      color: $colorPrimaryDark;
      width: calc(100% + 16px);
      padding: 8px;
      margin: 16px 0px 0px 0px;
      .modal-subheader-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .steps-count,
        .separation {
          color: $colorPrimary;
        }
      }
    }
  }

  .modal-buttons {
    display: flex;
    column-gap: 24px;
    width: 100%;
  }
  .modal-buttons-stack {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .formmodal-header-content {
    display: flex;
    justify-content: space-between;
  }
}

.formmodal-buttons {
  display: flex;
  column-gap: 24px;
  width: 100%;
  justify-content: end;
}

.formmodal-href {
  margin: 8px 0 0 0;
  text-align: center;
}

input[type='checkbox'] {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

label {
  display: block;
}
