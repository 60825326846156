@import '../../../assets//stylesheets/variables';
@import '../common/styles.scss';

.data-table {
  border: 1px solid $colorInactiveGrey;
  border-radius: 16px;
  border-collapse: separate !important;
  width: 100%;

  .data-table-row {
    th,
    td {
      border: 1px solid $colorDisabledGrey;
      border-top: none;
      border-left: none;
      padding: 16px;
    }

    td {
      color: $colorInactiveDarkGrey;
      white-space: nowrap;
      .container-in-cell {
        display: flex;
        flex-direction: column;
        .title-in-cell {
          font-size: 16px;
          color: $colorBlack;
          font-weight: 500;
        }
        .subtitle-in-cell {
          font-size: 12px;
          color: $colorInactiveGrey;
          font-weight: 400;
        }
      }
      .status-container {
        display: flex;
        flex-direction: column;
        .status-cell-data {
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          position: relative;
          justify-content: space-between;
          .status-container {
            padding: 2px 8px;
            background-color: $colorDisabledGrey;
            border-radius: 6px;
            width: fit-content;
            .status-value {
              color: $colorPrimaryDark;
              font-size: 12px;
              font-weight: 500;
              line-height: 24px;
            }
          }
          .tooltip-content {
            top: 40px;
          }
          .icon-font-a-error-message {
            color: $colorAlertNeutralDark;
          }
          .icon::before {
            top: 0px;
            font-size: 18px;
          }
        }
        .helper-text {
          color: $colorPrimaryDark;
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          align-self: flex-end;
        }
      }
    }

    th {
      background-color: $colorPrimaryLight;
      color: $colorPrimaryDark;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        font-weight: 500;
      }
    }

    th:first-child {
      border-radius: 16px 0px 0px 0px;
    }

    th:last-child {
      border-right: none;
      border-radius: 0px 16px 0px 0px;
    }

    td:first-child {
      border-radius: 16px 0px 0px 0px;
    }
    td:last-child {
      border-right: none;
      border-radius: 0px 16px 0px 0px;
    }

    .table-in-cell {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      td {
        border: none;
      }
    }
  }
  tr: {
    border: 1px solid $colorInactiveDarkGrey;
  }
  tr:last-child {
    td {
      border-bottom: none;
    }
  }
}
