@import 'assets/stylesheets/variables.scss';

.appointment-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.visits-form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .visits-name {
    width: 651px;
  }

  .visits-form-inputs {
    border: 2px solid $colorDisabledGrey;
    border-radius: 4px;
  }
}

.visit-note-form {
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  .visit-note-input {
    border: 2px solid $colorDisabledGrey;
    border-radius: 4px;
  }

  textarea {
    resize: none;
  }
  .character-limit {
    align-self: flex-end;
    color: $colorInactiveDarkGrey;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

.visits-form-row {
  display: flex;
  flex-direction: row;
  column-gap: 15px;

  .rdtPicker {
    width: inherit;
    top: 0;
  }

  .rowelement {
    width: 50%;

    .date-field {
      width: 100%;
    }
  }
}
