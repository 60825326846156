@charset "UTF-8";

@font-face {
  font-family: 'resend-invite-font-3';
  src: url('../fonts/resend-invite/resend-invite.eot');
  src: url('../fonts/resend-invite/resend-invite.eot')
      format('embedded-opentype'),
    url('../fonts/resend-invite/resend-invite.woff') format('woff'),
    url('../fonts/resend-invite/resend-invite.ttf') format('truetype'),
    url('../fonts/resend-invite/resend-invite.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'resend-invite-font-3' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-invite']:before,
[class*=' icon-invite']:before {
  font-family: 'resend-invite-font-3' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-invite-logged-in:before {
  content: '\61';
}
.icon-invite-notifications-off:before {
  content: '\62';
}
.icon-invite-phone-iphone-fill0-wght400-grad0-opsz48-1-2:before {
  content: '\63';
}
