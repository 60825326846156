@import '/src/assets/stylesheets/variables.scss';

.attachments-container {
  font-size: 12px;
  line-height: 25px;
  .attachments-count {
    font-weight: 300;
    display: flex;
    flex-direction: row;
    column-gap: 6px;
    align-items: center;
    color: $colorInactiveDarkGrey;
  }
}

// .attachment-seperator {
//   border-right: 1px solid $colorDisabledGrey;
//   margin: 10px 12px;
//   &:last-child {
//     border-right: 0;
//   }
// }
.attachment-media {
  height: 50px;
}
