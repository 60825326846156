@charset "UTF-8";

@font-face {
  font-family: 'untitled-font-6';
  src: url('../fonts/download-fonts/untitled-font-6.eot');
  src: url('../fonts/download-fonts/untitled-font-6.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/download-fonts/untitled-font-6.woff') format('woff'),
    url('../fonts/download-fonts/untitled-font-6.ttf') format('truetype'),
    url('../fonts/download-fonts/untitled-font-6.svg#untitled-font-6')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'untitled-font-6' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-download']:before,
[class*=' icon-download']:before {
  font-family: 'untitled-font-6' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download:before {
  content: '\a2';
}
