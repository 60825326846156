@import '/src/assets/stylesheets/variables.scss';

.landing-page-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-flow: row;
  gap: 0px 0px;
  grid-template-areas: 'hero-image-container actual-content-wrapper';

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'content-area';
  }
}

.content-area {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: 35% calc(47% - 60px) 18% 60px;
  gap: 0px 0px;
  grid-area: content-area;
  height: calc(100vh - 60px);
  @media screen and (max-width: 572px) {
    height: 100vh;
    grid-template-rows: 25% calc(47% - 30px) 28% 30px;
  }
}

.hero-image-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1.4fr 0.6fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    '.'
    '.';
  grid-area: hero-image-container;
}

.image-area {
  background-image: url(../../assets/images/heroimage2.png);
  @media screen and (max-width: 990px) {
    background-image: url(../../assets/images/heroimage1.png);
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0;
}

.portal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $colorPrimaryDark;
  color: $colorWhite;
  font-size: 12px;
  font-weight: 100;
  height: 100%;
  padding: 16px;
  row-gap: 16px;

  @media screen and (min-width: 1441px) and (max-width: 2560px) {
    font-size: 24px;
  }

  .patient-learn-more-container {
    display: flex;
    column-gap: 16px;
    align-items: center;
    font-size: $font-size-xxxs;
    font-weight: $font-weight-regular;
    line-height: 20px;
    overflow: auto;
    > img {
      height: 54px;
      width: 54px;

      @media screen and (min-width: 1441px) and (max-width: 2560px) {
        font-size: 48px;
      }
    }
    .patient-learn-more-message {
      overflow: auto;
    }
  }
}

.actual-content-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: calc(100vh - 120px) 60px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    '.'
    '.';
  grid-area: actual-content-wrapper;
  background-color: $colorSecondary;
}

.actual-content {
  background-color: $colorWhite;
  margin: 60px 90px 0 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 24px;
  padding: 90px;
  @media screen and (max-width: 1500px) {
    padding: 60px;
    margin: 60px 60px 0 60px;
  }

  @media screen and (max-width: 990px) {
    padding: 60px;
    margin: 0px 60px 0 60px;
  }

  @media screen and (max-width: 480px) {
    padding: 10px;
    margin: 0px;
    row-gap: 16px;
  }

  .content-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .header {
      font-style: normal;
      font-weight: $font-weight-medium;
      font-size: $font-size-header-xxxxl;
      line-height: 40px;
      margin: 0px 0 20px 0;
      color: $colorBlack;
      @media screen and (max-width: 990px) {
        margin: 0;
        font-size: $font-size-xl;
      }
      @media screen and (max-width: 480px) {
        display: none;
      }
    }
    .sub-header {
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: 1.9em;
      line-height: 36px;
      color: $colorBlack;
      > sup {
        font-size: 8px;
        line-height: 10px;
        vertical-align: super;
      }
      @media screen and (max-width: 990px) {
        margin: 0;
        font-size: $font-size-s;
      }
    }
  }
}
