@import '/src/assets/stylesheets/variables.scss';

.survey-header {
  font-weight: 300;
  .survey-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    width: 95%;
  }
  .edit-survey-btn {
    position: absolute;
    right: 0px;
    &:before {
      font-size: 18px;
      color: $colorPrimary;
    }
  }

  .clear-survey-button {
    text-transform: capitalize;
    color: $colorAlertNegative;
  }
}

.question-header {
  border-radius: 5px;
  margin-left: 23px;
  margin-right: -18px;
  margin-top: -2px;
  background: $colorSecondary;
  padding: 5px 10px;
}

.inline-block {
  display: inline-block;
  padding-bottom: 5px;
}

.survey-row {
  margin-left: 25px;
  padding: 5px 25px;
  border-bottom: 1px solid $colorDisabledGrey;
  line-height: 25px;
  .survey-question {
    font-weight: 500;
  }
  .survey-answer {
    font-weight: 300;
    padding: 5px;
  }
}
.show-only-in-print {
  visibility: hidden;
  display: none;
}
@media print {
  body * {
    visibility: hidden;
  }
  .hide-in-print {
    display: none;
    visibility: hidden;
  }
  .show-in-print {
    display: inline !important;
    visibility: visible !important;
  }
  .app-container {
    padding-top: 0;
  }
  .patient-details-main-container {
    .tab-menu {
      margin-top: 0;
    }
    .nav-tabs {
      display: none;
      visibility: hidden;
    }
    .tab-content {
      padding: 0;
      visibility: visible;
      display: inline;
    }
    .tabs-content-container {
      padding: 0;
      margin: 0;
    }
    .survey-row {
      margin-bottom: 20px;
      border: 1px solid $colorDisabledGrey;
      page-break-inside: avoid;
    }
    .tab-pane {
      margin-top: 30px;
    }
  }
  .selected-survey-name {
    margin: 30px;
  }
  .show-only-in-print {
    visibility: visible;
    display: inline;
  }
}
