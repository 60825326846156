@import 'assets/stylesheets/variables.scss';

.flash-message {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 62px;
  font-size: 16px;
  font-weight: 300;
  box-shadow: 0 0 20px -5px $colorBlack;
  z-index: 10000;
  padding: 5px;

  @media screen and (max-width: 768px) {
    min-height: 52px;
  }

  .toaster-close-icon {
    align-self: flex-start;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $colorWhite;
    background-color: $colorWhite;
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
      font-size: 20px !important;
    }
  }

  &.success {
    background-color: $colorAlertPositiveLight;
    border: 1px solid $colorDisabledGrey;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12),
      0px 20px 20px rgba(0, 0, 0, 0.08);
    .message-content {
      display: flex;
      justify-content: center;
      order: 0;
      flex: 1 1 auto;
      align-self: auto;
      text-align: center;
      flex-wrap: wrap;
      column-gap: 16px;
      line-height: 18px;
      font-weight: 300;
      align-items: center;
      @media screen and (max-width: 572px) {
        font-size: 12px;
      }
      .alertt {
        display: flex;
        align-items: center;

        width: 44px;
        height: 44px;

        /* Alert Negative Dark */

        background: $colorAlertPositiveDark;
        border-radius: 8px;
      }
    }
    .status-icon {
      left: 10px;
      position: relative;
      color: $colorWhite;

      @media screen and (max-width: 572px) {
        top: 5px;
      }
    }
    .header {
      color: $colorAlertPositive;
    }
    .message {
      left: 2px;
      color: $colorInactiveDarkGrey;
    }
  }

  &.error {
    background-color: $colorAlertNegativeLight;
    border: 1px solid $colorDisabledGrey;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12),
      0px 20px 20px rgba(0, 0, 0, 0.08);
    .message-content {
      display: flex;
      justify-content: center;
      order: 0;
      flex: 1 1 auto;
      align-self: auto;
      text-align: center;
      flex-wrap: wrap;
      column-gap: 16px;
      line-height: 18px;
      font-weight: 300;
      align-items: center;
      @media screen and (max-width: 572px) {
        font-size: 12px;
      }
      .alertt {
        display: flex;
        align-items: center;

        width: 44px;
        height: 44px;

        /* Alert Negative Dark */

        background: $colorAlertNegativeDark;
        border-radius: 8px;
      }
    }
    .status-icon {
      left: 10px;
      position: relative;
      color: $colorWhite;

      @media screen and (max-width: 572px) {
        top: 5px;
      }
    }
    .header {
      color: $colorAlertNegative;
    }
    .message {
      left: 2px;
      color: $colorInactiveDarkGrey;
    }
  }
}

.close-flash-icon {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;

  &.success {
    color: green;
  }
  &.error {
    color: $colorAlertNegative;
  }
}
