@import '/src/assets/stylesheets/variables.scss';
.edit-patient-form-body {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .disclaimer-text {
    color: $colorInactiveDarkGrey;
    margin: 0px;
  }
  .single-select__control {
    border: 2px solid $colorDisabledGrey !important;
  }
}
.patient-info-container {
  // margin-bottom: 20px;
  display: flex;
  // flex-direction: row;
  // column-gap: 10px;
  flex: auto;
  flex-wrap: wrap;
  // #main div:nth-of-type(1) {
  //   flex: 0 0 651px;
  // }

  .header {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid $colorDisabledGrey;
  }
  .body-content {
    display: flex;
    flex-direction: row;
    padding: 8px;
  }

  img {
    width: 88px;
    height: 88px;
    margin-left: 0px;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // min-width: 213px;
    padding: 20px 0px 20px 0px;

    .marketing-consent {
      position: relative;
      bottom: 20px;
      // width: 40px;
      // display: block;
      // left: 30px;
      // top: 102px;
      // top: -41px;
      // position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      @media screen and (max-width: 769px) {
        width: 30px;
      }
    }
    .no-marketing-consent {
      padding-top: 20px;
    }

    .user-loggedin-status {
      position: absolute;
      width: 40px;
      display: block;
      right: 30px;
      top: 102px;

      @media screen and (max-width: 769px) {
        right: 36px;
        top: 70px;
        width: 30px;
      }

      @media screen and (min-width: 770px) and (max-width: 1024px) {
        right: 20px;
        top: 110px;
      }

      @media screen and (min-width: 1025px) and (max-width: 1200px) {
        right: 50px;
        top: 110px;
      }

      @media screen and (min-width: 1201px) and (max-width: 1700px) {
        right: 21%;
        top: 110px;
      }

      @media screen and (min-width: 1701px) {
        right: 26%;
        top: 110px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .switch-container {
    font-weight: 400 !important;
    font-size: 16px !important;
    color: $colorInactiveGrey !important;
    display: flex;
    flex-direction: row;
    // margin-top: 10px;
    .switch {
      height: 12px;
      .slider:before {
        left: 3px;
      }
      .slider.round {
        width: 52px;
      }
    }
    .item {
      position: relative;
      .tip {
        top: 17px;
      }
      .tooltip-content {
        top: 22px;
      }
      .icon:before {
        color: $colorPrimary;
        font-size: 14px;
        margin-left: 4px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
  .alert-container {
    width: fit-content;
    .alert-text {
      color: $colorPrimary;
    }
  }
  .patient-name {
    font-weight: 400;
    font-size: 16px;
    top: 3px;
    .icon:before {
      font-size: 18px;
      top: 2px;
      color: $colorPrimary;
    }
    .icon.disabled:before {
      color: $colorDisabledGrey;
    }
  }
  .patient-address {
    font-weight: 400;
    font-size: 16px;
  }
  .patient-info-label {
    font-weight: 400 !important;
    font-size: 16px !important;
    color: $colorInactiveGrey !important;
  }
  .patient-info-value {
    font-weight: 400 !important;
    font-size: 16px !important;
  }
  .procedure-name {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;
    width: fit-content;

    .procedure-closure-status {
      .icon-font-a-tick-unfilled:before,
      .icon-font-a-error-message:before,
      .icon-font-b-expiring-task:before {
        font-size: 16px;
      }
      margin-left: 10px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .btn-update-valve {
    // border-image: $colorPrimaryGradient;
    // background: $colorPrimaryGradient;
    padding: 0px 20px;
    font-weight: 300;
    font-size: 14px;

    .icon:before {
      font-size: 16px;
    }

    @media screen and (max-width: 768px) {
      font-size: 11px;
      height: 25px;
      .icon:before {
        font-size: 11px;
        top: 1px;
      }
    }
  }

  .patient-email {
    font-size: 16px;
    font-weight: 400;
  }
  .provider-name,
  .general-cardiologist {
    font-size: 16px;
    font-weight: 400;
    .provider-icon:before {
      font-size: 16px;
      top: 2px;
      color: $colorInactiveDarkGrey;
    }
    .patient-advocate-icon {
      &:before {
        font-size: 16px;
        top: 2px;
        color: $colorInactiveDarkGrey;
      }
    }
    .send-message-icon:before {
      position: absolute;
      font-size: 30px;
      top: -1px;
      color: $colorDisabledGrey;
    }
    .name-text {
      margin: 0 5px;
    }
  }
  .switch-procedure-icon:before {
    color: $colorPrimary;
    top: 7px;
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
  }
  .procedure-date,
  .patient-advocate-info {
    font-weight: 400;
    font-size: 16px;
    margin-top: 6px;
    .icon:before {
      font-size: 18px;
      top: 2px;
      color: $colorPrimary;
    }
    .icon.disabled:before {
      color: $colorDisabledGrey;
    }
  }
  @media screen and (max-width: 768px) {
    .btn {
      padding: 5px;
    }
  }

  .resend-invite-date {
    color: $colorDisabledGrey;
    font-weight: 300;
    width: 90%;
    background: none;

    &:hover {
      color: $colorDisabledGrey;
      cursor: auto;
    }
  }

  .procedure-location-name {
    font-weight: 500;
    font-size: 16px;
    color: $colorInactiveDarkGrey;
    .procedure-location-name-label {
      font-weight: 400 !important;
      font-size: 16px !important;
      color: $colorInactiveGrey !important;
    }
    .procedure-location-name-value {
      color: $colorBlack;
      font-weight: 400 !important;
      font-size: 16px !important;
    }
  }
  .resverse-procedure {
    color: $colorAlertNegative;
    font-size: 16px;
  }

  .not-completed-tooltip {
    border-left: 4px solid $colorAlertNegative;
    padding-left: 10px;
    min-block-size: 26vh;
  }
  .partial-completed-tooltip {
    border-left: 4px solid $colorAlertNeutral;
    padding-left: 10px;
    min-block-size: 26vh;
  }
  .completed-tooltip {
    border-left: 4px solid $colorAlertPositive;
    padding-left: 10px;
    min-block-size: 26vh;
  }
  .light-font {
    font-weight: 300;
  }
  .dark-font {
    font-weight: 500;
  }

  // patient cards css
  .patient-cards {
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 24px;
    flex-wrap: wrap;
    row-gap: 16px;
    .subject {
      flex: 1;
      min-width: 28%;
      .subject-info-body {
        display: flex;
        flex-direction: row;
        column-gap: 24px;
        flex: 0.3 auto;
        width: 100%;
        .patient-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 16px 0px 20px 0px;
          img {
            width: 88px;
            height: 88px;
            margin-left: 0px;
            margin: 0 auto;
            border-radius: 40px;
            @media screen and (max-width: 768px) {
              width: 100px;
              height: 100px;
            }
          }
        }
        .patient-info-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 16px 0px 16px 0px;
          row-gap: 4px;
          button {
            padding: 0px;
          }
          .patient-id {
            font-weight: 700;
            font-size: 20px;
            color: $colorBlack;
          }
          .subject-details {
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            align-self: start;

            .subject-details-icon {
              -ms-flex-align: start;
            }
            .subject-details-text {
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              padding-left: 2px;
            }
          }
          .switch-container {
            font-weight: 500 !important;
            font-size: 14px !important;
            color: $colorInactiveDarkGrey !important;
            padding-top: 4px;
            .switch {
              height: 12px;
              .slider:before {
                left: 3px;
              }
              .slider.round {
                width: 52px;
              }
            }
            .item {
              position: relative;
              .tip {
                top: 17px;
              }
              .tooltip-content {
                top: 22px;
              }
              .icon:before {
                color: $colorPrimary;
                font-size: 14px;
                margin-left: 4px;
                vertical-align: middle;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .study {
      // flex: 1 1 0;
      .study-info-body {
        display: flex;
        // grid-template-columns: repeat(3, 1fr);
        width: 100%;
        padding: 8px 8px 16px 8px;
        column-gap: 16px;
        .study-column1 {
          display: flex;
          flex-direction: column;
          width: 50%;
          font-size: 14px;
          font-weight: 400;
          row-gap: 16px;
          .study-column-row {
            display: flex;
            flex-direction: row;
            column-gap: 8px;
            min-height: 34px;
            align-items: center;
            .study-column-labels {
              color: $colorInactiveDarkGrey;
              min-width: 65px;
            }
            .info {
              min-width: calc(100% - 73px);

              .restore-state-icon {
                color: $colorInactiveDarkGrey;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        .study-column2 {
          display: flex;
          flex-direction: column;
          width: 50%;
          font-size: 14px;
          font-weight: 400;
          row-gap: 16px;

          .study-column-row {
            display: flex;
            flex-direction: row;
            column-gap: 8px;
            min-height: 34px;
            align-items: center;
            .study-column-labels {
              min-width: 65px;
              color: $colorInactiveDarkGrey;
            }
            .info {
              min-width: calc(100% - 73px);
            }
          }
        }
        .info {
          display: flex;
          white-space: nowrap;
          column-gap: 8px;
          align-items: center;
        }
        .select-parameter {
          width: 100%;
          @media screen and (min-width: 1221px) {
            width: 90%;
          }
        }
      }
    }
    .team {
      // flex: 1 1 0;
      flex: 1;
      min-width: 25%;
      .team-info-body {
        display: flex;
        flex-direction: column;
        // grid-template-columns: repeat(3, 1fr);
        width: 100%;
        padding: 8px 8px 16px 8px;
        row-gap: 16px;
        .team-info-key-value {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .team-name-label {
            font-weight: 400;
            font-size: 14px;
            min-height: 24px;
            color: $colorInactiveDarkGrey;
          }
          .team-name {
            font-weight: 400;
            font-size: 14px;
            min-height: 24px;
          }
        }
      }
    }

    .card-title {
      padding: 0px 0px 0px 8px;
      color: $colorBlack;
      font-weight: 500;
      font-size: 18px;
    }
    .action {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      .login-status-icon {
        border: 1px solid $colorInactiveGrey;
      }
      .pending-invite-icon {
        background-color: $colorInactiveDarkGrey;
        .phone-icon-send-invite {
          color: $colorWhite;
          font-size: 18px;
        }
      }
      .logged-in-icon {
        background-color: $colorPrimary;
        .icon-invite-logged-in {
          color: $colorWhite;
          font-size: 18px;
        }
      }
      .notification-off-icon {
        background-color: $colorAlertNeutral;
        .icon-invite-notifications-off {
          font-size: 18px;
        }
      }
      .not-logged-in-icon {
        border: 1px solid $colorInactiveGrey;
        background-color: $colorAlertNegativeLight;
        .icon-not-logged-in {
          color: $colorBlack;
          font-size: 22px;
          top: 1px;
          position: relative;
        }
      }

      .action-text {
        padding: 4px;
        font-weight: 400;
        font-size: 12px;
        align-self: center;
      }
    }
    @media screen and (max-width: 1220px) {
      flex-wrap: wrap;
      .subject {
        order: 1;
      }
      .study {
        order: 3;
        flex: 0 1 100%;
      }
      .team {
        order: 2;
      }
    }
  }
}

.create-in-nlink,
.reassign-patient,
.add-procedures,
.remove-patient,
.resend-invite,
.send-message-button-container {
  border: 1px solid $colorPrimary;
  width: 90%;
  margin-top: 10px;
  .icon:before {
    font-size: 16px;
    top: 2px;
    right: 5px;
  }
  .send-message-icon:before {
    font-size: 24px;
  }
  span {
    display: inline-block;
  }
  @media screen and (max-width: 768px) {
    width: 104%;
  }
}

.label-separaion {
  margin-bottom: 5px;
  display: inline-block;
}

.email-phone-disclaimer {
  &.has-error {
    color: $colorAlertNegative;
  }
}

.general-cardiologist-info {
  padding: 5px 10px;
  background-color: $colorDisabledGrey;
  font-size: 12px;
  margin: 15px 0;
  color: $colorInactiveGrey;
  border-radius: 4px;
}

.no-data-label {
  color: $colorDisabledGrey;
}
.update-valve-modal {
  .update-valve-content {
    padding-bottom: 16px;
    p {
      font-size: 16px !important;
    }
  }
  label {
    font-size: 16px !important;
  }
}

.end-trial-container {
  padding: 20px;
  font-weight: 500;
  font-size: 16px;
  .question-text {
    font-weight: 700;
    padding-bottom: 15px;
  }
  p {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.pain-reduction-container {
  margin-left: 10px;
  line-height: 28px;
  font-weight: 300;
  font-size: 20px;
  padding: 0 6px;
  vertical-align: top;
  text-align: center;
  .small-text {
    font-size: 10px;
    vertical-align: middle;
  }

  &.survey-value-100 {
    border: 1px solid;
    border-color: $painReductionColor100;
    color: $painReductionColor100;
  }
  &.survey-value-90 {
    border: 1px solid;
    border-color: $painReductionColor90;
    color: $painReductionColor90;
  }
  &.survey-value-80 {
    border: 1px solid;
    border-color: $painReductionColor80;
    color: $painReductionColor80;
  }
  &.survey-value-70 {
    border: 1px solid;
    border-color: $painReductionColor70;
    color: $painReductionColor70;
  }
  &.survey-value-60 {
    border: 1px solid;
    border-color: $painReductionColor60;
    color: $painReductionColor60;
  }
  &.survey-value-50 {
    border: 1px solid;
    border-color: $painReductionColor50;
    color: $painReductionColor50;
  }
  &.survey-value-40 {
    background-color: $painReductionColor40;
    color: $colorWhite;
  }
  &.survey-value-30 {
    background-color: $painReductionColor30;
    color: $colorWhite;
  }
  &.survey-value-20 {
    background-color: $painReductionColor20;
    color: $colorWhite;
  }
  &.survey-value-10 {
    background-color: $painReductionColor10;
    color: $colorWhite;
  }
  &.survey-value-0 {
    background-color: $painReductionColor00;
    color: $colorWhite;
  }

  @media screen and (max-width: 572px) {
    font-size: 12px;
  }
}
.has-error {
  .multi-select__control,
  .single-select__control {
    border-color: $colorAlertNegative !important;
  }
}

.procedure-detail-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2px;
  line-height: 32px;
}

.procedure-details-label {
  white-space: nowrap;
  margin-left: 8px;
  font-size: 14px;
  padding-block-start: 4px;
}

.date-location-tag-follow-up {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.marketing-consent-close-button-container {
  display: flex;
  justify-content: center;
}
.marketing-consent {
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}
.service-consent {
  font-size: 16px;
  font-weight: 400;
  white-space: normal !important;
}
.no-marketing-consent {
  padding-top: 16px;
}

.salesforce-checkbox-wrapper {
  margin-top: 3px;
  margin-bottom: -12px;
  .MuiCheckbox-root {
    color: $salesforceBlueColor;
    &.Mui-disabled {
      color: $salesforceBlueColor;
    }
  }
}

.salesforce-modal-wrapper {
  .modal-dialog {
    width: 495px;
  }
  .salesforce-modal-response-button {
    width: 180px;
  }
  .salesforce-modal-body {
    font-size: 17px;
    padding-inline: 43px;
    text-align: center;
  }
  .modal-footer {
    text-align: center;
    padding-inline: 48px;
    .btn-default {
      margin-left: 0;
    }
  }
}
