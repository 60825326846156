@import 'assets/stylesheets/variables.scss';

.single-select,
.multi-select {
  &__placeholder,
  &__single-value {
    font-weight: 400;
  }

  &__menu {
    margin: 4px 0;
  }

  &__option {
    padding: 6px 10px !important;
  }

  &__placeholder,
  &__single-value {
    font-weight: 300;
  }
}

.single-select__control.single-select__control--is-focused {
  .single-select__placeholder.css-1jqq78o-placeholder {
    color: $colorPrimary;
  }
  .single-select__value-container.single-select__value-container--has-value.css-1hl9yg6-ValueContainer {
    .single-select__single-value.css-1dimb5e-singleValue {
      color: $colorPrimary;
    }
  }
}
.single-select__placeholder.css-1jqq78o-placeholder {
  color: $colorInactiveGrey;
}

.single-select__control.css-1fh3aur-control {
  border-color: $colorInactiveGrey;
}

.single-select__indicator.single-select__dropdown-indicator.css-yjbq0y-indicatorContainer {
  color: $colorPrimary;
}

.single-select__indicator.single-select__dropdown-indicator.css-1qajzci-indicatorContainer {
  color: $colorInactiveGrey;
}
// &:focus{

// }
// &:active{
//   color: $colorPrimary;

// }

.dropdown-icon {
  font-size: 24px !important;
}
.select-clear-icon {
  &.icon::before {
    font-size: 16px;
    position: relative;
    top: 2px;
    right: 6px;
    color: $colorPrimary;
    cursor: pointer;
  }
}

.multi-select__multi-value__remove {
  &:hover,
  &:focus {
    background-color: transparent !important;
    color: $colorPrimary !important;
  }
}

.multi-select__multi-value__label {
  color: $colorPrimary !important;
}

.multi-select {
  &__option {
    display: flex !important;
    align-items: center !important;
    input.option--selector {
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
    }
    // label {
    //   flex: 1;
    //   margin: 0;
    //   padding: 0;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }
    & > input[type='checkbox'] {
      // width:20px !important;
      // height:20px !important;
      // display: flex !important;
    }

    & > label {
      // display: flex !important;
      margin-left: 5px !important;
      padding-top: 0 !important;
    }
  }
}

.single-select__menu-list {
  font-weight: 300;
  .single-select__option {
    cursor: pointer;
    &:hover {
      font-weight: 400;
    }
  }
  .single-select__option.single-select__option--is-selected {
    color: $colorPrimaryDark;
    background-color: $colorSecondaryDark;
  }
}

.multi-select__option {
  label {
    span {
      @media screen and (min-width: 768px) {
        display: inline;
      }
      display: inline-block;
      &:first-of-type {
        &:before {
          color: $colorPrimary;
          font-size: 20px;
        }
        vertical-align: middle;
        margin-right: 7px;
      }
    }
  }
}

.multi-select__option--is-selected {
  background-color: $colorWhite !important;
  color: $colorBlack !important;
}

.without-border {
  .single-select {
    &__control {
      // border-radius: 0;
      // border: none;
      // border-bottom: 1px solid $colorDisabledGrey;
      // box-shadow: none;
      // &:hover, &:focus{
      //   border-bottom: 1px solid $colorPrimary;
      // }
    }

    &__indicator {
      color: $colorPrimary;
      padding: 4px 0 4px 4px;
      &:hover,
      &:focus {
        color: $colorPrimary;
      }
    }
    &__indicator-separator {
      display: none;
    }

    &__value-container {
      padding: 0 3px;
    }
  }
}

.value-container--value-outer {
  display: flex;
  flex: 1;
  overflow: hidden;
  font-size: 15px;
  font-weight: 300;
  color: $colorWhite;

  .value-container--value-label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.multi-select__control {
  border-color: $colorPrimary !important;
  .multi-select__dropdown-indicator {
    color: $colorPrimary;
  }
}

.css-acxf5b-ValueContainer {
  display: flex !important;
  flex-direction: row;
}
